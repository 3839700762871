/* frontend/src/Styles/UserSettings.css */

.user-settings-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  height: auto;
  min-height: 100%;
  overflow-y: auto;
  padding-bottom: 50px; /* Add padding at bottom for better UX */
}

/* Ensure content stays within viewport and scrolls */
.settings-layout {
  display: flex;
  gap: 30px;
  margin-top: 20px;
  overflow: visible;
  height: auto;
}

/* Fix sidebar to not scroll independently */
.settings-sidebar {
  width: 220px;
  flex-shrink: 0;
  position: sticky;
  top: 70px; /* Adjust based on your navbar height plus some padding */
  height: fit-content;
  max-height: calc(100vh - 100px);
}

.tab-button {
  display: block;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 8px;
  text-align: left;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #333;
}

.tab-button:hover {
  background-color: #e8e8ff;
  border-color: #4c11e0;
}

.tab-button.active {
  background-color: #4c11e0;
  color: white;
  font-weight: 600;
  border-color: #3a0db0;
}

/* Content Area */
/* Make content area scrollable */
.settings-content {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 25px;
  overflow: visible;
  height: auto;
}

/* Override any overflow:hidden from parent elements */
body, html, #root, .app-container, .page-content {
  overflow-y: auto !important;
}

.settings-section h2 {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 1.5rem;
  color: #333;
  height: auto;
  overflow: visible;
}

/* Forms */
.settings-form {
  max-width: 600px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #4c11e0;
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 17, 224, 0.1);
}

.disabled-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.8;
}

.form-hint {
  font-size: 0.85rem;
  color: #777;
  margin-top: 5px;
}

/* Buttons */
.primary-button, 
.secondary-button,
.danger-button {
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.primary-button {
  background-color: #4c11e0;
  color: white;
}

.primary-button:hover {
  background-color: #3a0db0;
}

.primary-button:disabled {
  background-color: #9f83e5;
  cursor: not-allowed;
}

.secondary-button {
  background-color: #f0f0f0;
  color: #333;
}

.secondary-button:hover {
  background-color: #e4e4e4;
}

.secondary-button:disabled {
  color: #999;
  cursor: not-allowed;
}

.danger-button {
  background-color: #e74c3c;
  color: white;
}

.danger-button:hover {
  background-color: #c0392b;
}

.text-button {
  background: none;
  border: none;
  padding: 5px;
  color: #4c11e0;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline;
}

.text-button:hover {
  color: #3a0db0;
}

/* Security Tab */
.security-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.card-header h3 {
  margin: 0;
  font-size: 1.25rem;
}

.security-status {
  margin-bottom: 15px;
}

.security-status .enabled {
  color: #27ae60;
  font-weight: 600;
}

.security-status .disabled {
  color: #7f8c8d;
}

.two-factor-setup {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.qr-code-container img {
  max-width: 200px;
  height: auto;
}

.setup-code {
  font-family: monospace;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin: 15px 0;
}

.verification-form {
  margin-top: 20px;
}

.code-input-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.code-input-container input {
  flex-grow: 1;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 4px;
}

.info-text {
  color: #666;
  line-height: 1.5;
}

/* Subscription Tab */
.subscription-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

.subscription-info {
  flex-grow: 1;
}

.plan-badge {
  display: inline-block;
  background-color: #4c11e0;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 600;
  margin: 10px 0;
}

.usage-preview {
  margin-top: 15px;
}

.usage-preview ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
}

.usage-preview li {
  padding: 5px 0;
}

.subscription-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.feature-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.feature-icon {
  margin-right: 15px;
  font-weight: bold;
  width: 20px;
  text-align: center;
}

.feature-item.disabled {
  color: #999;
}

.billing-history {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
}

/* App Settings Tab */
.settings-group {
  margin-bottom: 30px;
}

.settings-group h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.settings-toggle {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4c11e0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4c11e0;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.settings-radio-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

/* Data & Privacy Tab */
.data-export-card,
.compliance-card,
.delete-account-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.compliance-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.compliance-links a {
  color: #4c11e0;
  text-decoration: none;
}

.compliance-links a:hover {
  text-decoration: underline;
}

.confirm-delete {
  margin-top: 20px;
}

.warning-text {
  color: #e74c3c;
  font-weight: 500;
  margin-bottom: 15px;
}

.confirm-buttons {
  display: flex;
  gap: 15px;
}

/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #666;
}

/* Responsive design */
@media (max-width: 768px) {
  .settings-layout {
    flex-direction: column;
  }
  
  .settings-sidebar {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 10px;
  }
  
  .subscription-summary {
    flex-direction: column;
  }
  
  .subscription-actions {
    margin-top: 20px;
    align-items: flex-start;
  }
  
  .features-list {
    grid-template-columns: 1fr;
  }
  
  .card-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .card-header button {
    align-self: flex-start;
  }
}

/* Confirmation Dialog */
.confirmation-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.confirmation-dialog p {
  margin-bottom: 20px;
}

.confirm-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* For dark mode support */
body.dark-mode .confirmation-dialog {
  background-color: #333;
  color: white;
}

/* Step Tracking Settings */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.help-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: transparent;
  color: #4c11e0;
  border: 1px solid #4c11e0;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.help-button:hover {
  background: rgba(76, 17, 224, 0.1);
}

.help-panel {
  background: #f7f7ff;
  border-left: 3px solid #4c11e0;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 0 4px 4px 0;
}

.help-panel h4 {
  margin-top: 0;
  color: #4c11e0;
}

.help-panel ul {
  padding-left: 20px;
}

.help-panel li {
  margin-bottom: 8px;
}

.method-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.method-option {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.method-option:hover {
  border-color: #4c11e0;
  background: rgba(76, 17, 224, 0.03);
}

.method-option.selected {
  border-color: #4c11e0;
  background: rgba(76, 17, 224, 0.05);
}

.method-option input[type="radio"] {
  margin: 0;
}

.method-icon {
  font-size: 24px;
  color: #4c11e0;
}

.method-details {
  display: flex;
  flex-direction: column;
}

.method-name {
  font-weight: 600;
  color: #333;
}

.method-description {
  font-size: 12px;
  color: #666;
}

.permission-request {
  background: #fff8e1;
  border-left: 3px solid #ffc107;
  padding: 15px;
  margin: 10px 0 20px;
  border-radius: 0 4px 4px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.permission-request p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.info-icon {
  color: #ffc107;
}

.step-length-settings {
  margin-bottom: 20px;
}

.setting-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.numeric-input {
  width: 100px;
  padding: 8px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.input-unit {
  color: #666;
  font-size: 14px;
}

.height-calculator {
  background: #f5f5f5;
  padding: 12px;
  border-radius: 6px;
  margin-top: 15px;
}

.height-calculator p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.auto-tracking-settings {
  margin-top: 25px;
}

.battery-warning {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  padding: 10px;
  background: rgba(255, 193, 7, 0.1);
  border-radius: 4px;
  font-size: 13px;
  color: #f57c00;
}

.warning-icon {
  color: #f57c00;
}