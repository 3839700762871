/* ProgramCreator.css */
.program-modal {
    max-width: 900px;
    width: 95%;
    max-height: 90vh;
}

.mode-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    width: fit-content;
    margin: 0 auto 20px;
}

.mode-button {
    padding: 10px 20px;
    background-color: #f5f5f5;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
    width: 200px;
}

.mode-button.active {
    background-color: #4c11e0;
    color: white;
}

.program-container, .single-workout-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.week-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.week-navigation button {
    padding: 8px 15px;
    border: none;
    background-color: #4c11e0;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.week-navigation button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.week-navigation span {
    font-weight: 500;
    color: #333;
}

.week-workouts, .day-plans {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
}

.workout-card, .day-card, .add-workout-card {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.workout-card:hover, .day-card:hover, .add-workout-card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

.workout-card.selected, .day-card.selected {
    border: 2px solid #4c11e0;
    background-color: #f8f5ff;
}

.workout-card-header, .day-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.workout-card-header h4, .day-card-header h4 {
    margin: 0;
    color: #4c11e0;
    font-size: 16px;
}

.workout-actions, .day-actions {
    display: flex;
    gap: 5px;
}

.workout-actions button, .day-actions button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    transition: all 0.2s ease;
}

.workout-actions button:hover, .day-actions button:hover {
    background-color: #f0f0f0;
    color: #4c11e0;
}

.workout-card-content, .day-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.workout-type, .exercise-count, .meal-count {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}

.add-workout-card {
    background-color: #f9f9f9;
    border: 2px dashed #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.add-icon {
    font-size: 24px;
    color: #999;
    margin-bottom: 10px;
}

.workout-editor, .day-editor {
    background-color: #f8f5ff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.workout-editor h3, .day-editor h3 {
    margin-top: 0;
    color: #4c11e0;
    margin-bottom: 15px;
}

.workout-editor-buttons, .day-editor-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.program-overview, .workout-overview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.overview-item {
    display: flex;
    margin-bottom: 8px;
}

.overview-item strong {
    min-width: 150px;
    color: #333;
}

.program-week {
    margin-bottom: 25px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
}

.program-week h4 {
    margin-top: 0;
    color: #4c11e0;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
    margin-bottom: 15px;
}

.week-overview {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
}

.day-card {
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.day-header {
    font-weight: 500;
    color: #4c11e0;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
    margin-bottom: 8px;
}

.day-content {
    font-size: 14px;
}

.day-workout-title {
    color: #333;
    font-weight: 500;
}

.day-workout-type, .day-workout-exercises {
    color: #666;
    font-size: 12px;
}

.day-rest {
    color: #999;
    text-align: center;
    font-style: italic;
}

.has-workout {
    border-left: 3px solid #4c11e0;
}

.rest-day {
    background-color: #f9f9f9;
    border-left: 3px solid #ccc;
}

.meal-group-item {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
}

.meal-group-header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.meal-group-header:hover {
    background-color: #f0f0f0;
}

.meal-group-header h5 {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.meal-group-select {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    color: #999;
    transition: all 0.2s ease;
}

.meal-group-select.active {
    background-color: #4c11e0;
    color: white;
}

.copy-options {
    margin-top: 20px;
}

.copy-options h4 {
    color: #4c11e0;
    margin-bottom: 10px;
}

.copy-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.copy-button {
    padding: 8px 15px;
    background-color: #f0f0f0;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
}

.copy-button:hover {
    background-color: #e6e6e6;
    color: #4c11e0;
}

/* For small screens */
@media (max-width: 768px) {
    .week-workouts, .day-plans {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
    
    .week-overview {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
    
    .mode-button {
        width: 150px;
        padding: 8px 15px;
    }
}