/* Innovative Dashboard Styles */
:root {
    --primary-color: #6a1b9a;
    --secondary-color: #8e24aa;
    --accent-color: #ff4081;
    --dark-bg: #121212;
    --card-bg: #1d1d1d;
    --text-primary: #ffffff;
    --text-secondary: rgba(255, 255, 255, 0.7);
    --shadow-color: rgba(0, 0, 0, 0.3);
    --hydration-gradient: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
    --nutrition-gradient: linear-gradient(135deg, #43e97b 0%, #38f9d7 100%);
    --workout-gradient: linear-gradient(135deg, #fa709a 0%, #fee140 100%);
    --steps-gradient: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  }
  
  .innovative-dashboard-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    color: var(--text-primary);
    min-height: 90vh;
    perspective: 1000px;
    overflow-x: hidden;
  }
  
  .innovative-dashboard-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    color: var(--text-primary);
  }
  
  .pulse-loader {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primary-color);
    animation: pulse 1.5s ease-in-out infinite;
    margin-bottom: 20px;
  }
  
  @keyframes pulse {
    0% { transform: scale(0.8); opacity: 0.7; }
    50% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(0.8); opacity: 0.7; }
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .dashboard-header h1 {
    font-size: 2rem;
    margin: 0;
    background: linear-gradient(90deg, var(--primary-color), var(--accent-color));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 1px 2px var(--shadow-color);
  }
  
  .user-streak {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 8px 16px;
    border-radius: 30px;
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 6px var(--shadow-color);
  }
  
  .streak-icon {
    color: gold;
    margin-right: 8px;
  }
  
  .streak-count {
    font-size: 1.5rem;
    font-weight: bold;
    color: gold;
    margin-right: 5px;
  }
  
  .streak-label {
    color: var(--text-secondary);
    font-size: 0.9rem;
  }
  
  .error-message {
    background-color: rgba(231, 76, 60, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 8px var(--shadow-color);
  }
  
  .dashboard-navigation {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 15px;
    flex-wrap: wrap; /* Allow buttons to wrap on small screens */
  }
  
  .nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 15px;
    border: none;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);
    color: var(--text-secondary);
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 80px; /* Reduced minimum width for mobile */
    backdrop-filter: blur(5px);
  }
  
  .nav-button svg {
    font-size: 1.5rem; /* Larger icon */
    margin-bottom: 8px;
    color: rgb(16, 15, 15); /* Bright white color */
    filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.4)); /* Glow effect */
    opacity: 1; /* Full opacity */
  }
  
  .nav-button span {
    font-size: 0.9rem;
    font-weight: 500; /* Slightly bolder text */
    color: rgb(15, 15, 15); /* Bright white text */
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5); /* Text shadow for better readability */
  }
  
  .nav-button:hover {
    background: rgba(60, 60, 60, 0.9); /* Darker on hover */
    transform: translateY(-3px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.2); /* Brighter border on hover */
  }
  
  /* Make active button stand out more */
  .nav-button.active {
    background: var(--primary-color); /* Use the primary purple color */
    color: white;
    box-shadow: 0 10px 15px -3px rgba(106, 27, 154, 0.5); /* Colored shadow */
    transform: translateY(-5px);
    border: 1px solid rgba(255, 255, 255, 0.3); /* Brighter border */
  }
  
  .nav-button.active svg {
    filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.6)); /* Stronger glow for active icon */
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .nav-button {
      padding: 10px 12px;
      min-width: 70px;
    }
    
    .nav-button svg {
      font-size: 1.3rem; /* Still keep icons relatively large on mobile */
    }
  }
  
  .dashboard-content {
    position: relative;
  }
  
  .dashboard-widgets-container {
    display: grid;
    grid-template-columns: 1fr; /* Default to single column for mobile */
    gap: 25px;
  }
  
  .dashboard-widget {
    background: var(--card-bg);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 10px 30px -5px var(--shadow-color);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .dashboard-widget:hover {
    box-shadow: 0 15px 40px -5px var(--shadow-color);
  }
  
  .primary-widget {
    grid-column: 1;
    min-height: 400px; /* Reduced height for mobile */
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .secondary-widgets {
    display: flex;
    flex-direction: column;
    gap: 25px;
    grid-column: 1; /* Full width on mobile */
  }
  
  .challenge-widget, .summary-widget {
    min-height: 200px; /* Reduced height for mobile */
  }
  
  .widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .widget-title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .grocery-cart-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--accent-color);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .grocery-cart-button:hover {
    transform: scale(1.1);
  }
  
  .widget-content {
    padding: 20px;
    height: calc(100% - 60px);
    overflow-y: auto; /* Add scroll if content overflows */
  }
  
  /* Hydration Content Styles */
  .hydration-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .progress-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
  
  .progress-wrapper {
    position: relative;
    width: 100px; /* Smaller on mobile */
    height: 250px; /* Smaller on mobile */
    background: rgba(0, 0, 0, 0.2);
    border-radius: 70px;
    overflow: hidden;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.1);
  }
  
  .progress-fill {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: height 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  
  .progress-water-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    overflow: hidden;
  }
  
  .water-wave {
    position: absolute;
    top: -5px;
    left: 0;
    width: 200%;
    height: 10px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    animation: wave 2s infinite linear;
  }
  
  @keyframes wave {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }
  
  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--text-primary);
    font-weight: bold;
    text-align: center;
    width: 100%;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .quick-actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow buttons to wrap on small screens */
    gap: 10px;
    margin-top: 20px;
  }
  
  .quick-action-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 30px;
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    min-height: 44px; /* Ensure buttons are tappable on mobile */
  }
  
  .quick-action-button:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 5px 10px var(--shadow-color);
  }
  
  /* Animation classes */
  .fade-in {
    animation: fadeIn 0.5s forwards;
  }
  
  .fade-out {
    animation: fadeOut 0.3s forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; transform: translateY(0); }
    to { opacity: 0; transform: translateY(-20px); }
  }
  
  /* Steps Content Styles */
  .steps-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  
  .steps-progress .progress-wrapper {
    background: rgba(246, 211, 101, 0.1);
  }
  
  .steps-stats {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 30px;
    flex-wrap: wrap; /* Allow stats to wrap on small screens */
    gap: 15px;
  }
  
  .stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    padding: 15px;
    border-radius: 12px;
    min-width: 120px;
    flex: 1; /* Allow items to grow */
    min-width: 0; /* Prevent items from overflowing */
  }
  
  .stats-label {
    font-size: 0.9rem;
    color: var(--text-secondary);
    margin-bottom: 8px;
  }
  
  .stats-value {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  /* Summary Widget Styles */
  .summary-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .summary-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    transition: all 0.2s ease;
  }
  
  .summary-item:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateX(5px);
  }
  
  .summary-icon {
    font-size: 1.5rem;
    margin-right: 15px;
    padding: 10px;
    border-radius: 50%;
  }
  
  .hydration-icon {
    background: rgba(79, 172, 254, 0.2);
    color: #4facfe;
  }
  
  .steps-icon {
    background: rgba(246, 211, 101, 0.2);
    color: #f6d365;
  }
  
  .calories-icon {
    background: rgba(67, 233, 123, 0.2);
    color: #43e97b;
  }
  
  .summary-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0; /* Prevent text overflow */
  }
  
  .summary-label {
    font-size: 0.9rem;
    color: var(--text-secondary);
  }
  
  .summary-value {
    font-size: 1.1rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Modal Styles */
  .grocery-list-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: modalFadeIn 0.3s forwards;
  }
  
  @keyframes modalFadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Responsive design for tablet and desktop */
  @media (min-width: 768px) {
    .dashboard-widgets-container {
      grid-template-columns: 1fr 350px; /* Two columns on larger screens */
    }
    
    .primary-widget {
      min-height: 500px; /* Larger on desktop */
    }
    
    .challenge-widget, .summary-widget {
      min-height: 230px; /* Larger on desktop */
    }
    
    .secondary-widgets {
      grid-column: 2; /* Right column on larger screens */
    }
    
    .progress-wrapper {
      width: 120px; /* Larger on desktop */
      height: 300px; /* Larger on desktop */
    }
    
    .nav-button {
      min-width: 100px; /* Larger on desktop */
    }
  }
  
  /* Responsive design for small mobile screens */
  @media (max-width: 480px) {
    .dashboard-header {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
    
    .dashboard-header h1 {
      font-size: 1.6rem;
      text-align: center;
    }
    
    .nav-button {
      padding: 8px 12px;
      min-width: 70px;
    }
    
    .nav-button svg {
      font-size: 1.1rem;
      margin-bottom: 5px;
    }
    
    .nav-button span {
      font-size: 0.8rem;
    }
    
    .progress-wrapper {
      width: 80px; /* Even smaller on very small screens */
      height: 200px; /* Even smaller on very small screens */
    }
    
    .widget-title {
      font-size: 1rem;
    }
    
    .quick-action-button {
      padding: 8px 12px;
      font-size: 0.8rem;
    }
    
    .stats-item {
      padding: 10px;
    }
    
    .stats-value {
      font-size: 1rem;
    }
    
    .summary-icon {
      font-size: 1.2rem;
      padding: 8px;
    }
    
    .summary-value {
      font-size: 0.9rem;
    }
  }

  .summary-breakdown {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 0.8rem;
    color: #fdfcfc;
    margin-top: 3px;
  }
  
  .summary-details {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .summary-value {
    font-weight: 600;
    color: #f7f7f7;
  }
  
  /* Add tooltip for calorie calculation method */
  .calorie-info {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    cursor: help;
  }
  
  .calorie-info i {
    color: #4facfe;
    font-size: 14px;
  }
  
  .calorie-info .tooltip {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -125px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.75rem;
    font-weight: normal;
  }
  
  .calorie-info .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .calorie-info:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  /* Add a metrics cache indicator if needed */
  .metrics-cached {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4facfe;
    margin-left: 5px;
  }
  
  .metrics-missing {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffaa00;
    margin-left: 5px;
  }