/* SubscriptionBanner.css */
.subscription-banner {
    position: relative;
    width: 100%;
    background-color: #f7f8fc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
    border-left: 4px solid #4c11e0;
  }
  
  .subscription-banner.near-limit {
    border-left: 4px solid #ff9800;
    background-color: #fff8e6;
  }
  
  .subscription-banner.expanded {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  
  .banner-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
    user-select: none;
  }
  
  .banner-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #333;
    font-size: 0.9rem;
  }
  
  .banner-title .icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  .banner-actions {
    display: flex;
    align-items: center;
  }
  
  .expand-toggle, .dismiss-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #777;
    padding: 0;
    margin-left: 10px;
    transition: color 0.2s;
  }
  
  .expand-toggle:hover, .dismiss-button:hover {
    color: #333;
  }
  
  .banner-content {
    padding: 0 15px 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.3s ease;
  }
  
  .banner-message {
    margin: 15px 0;
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
  }
  
  .usage-meters {
    margin: 15px 0;
  }
  
  .meter {
    margin-bottom: 12px;
  }
  
  .meter-label {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-bottom: 5px;
    color: #666;
  }
  
  .meter-bar {
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .meter-progress {
    height: 100%;
    border-radius: 4px;
    transition: width 0.5s ease;
  }
  
  .meter-progress.workout {
    background-color: #4c11e0;
  }
  
  .meter-progress.meal {
    background-color: #00b4d8;
  }
  
  .meter-progress.coach {
    background-color: #10b981;
  }
  
  /* Change meter color when nearing limit */
  .near-limit .meter-progress.workout,
  .near-limit .meter-progress.meal,
  .near-limit .meter-progress.coach {
    background-color: #ff9800;
  }
  
  /* Make meter red when at limit */
  .near-limit .meter-progress[style*="width: 100%"] {
    background-color: #f44336;
  }
  
  .banner-cta {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  
  .upgrade-button {
    display: inline-block;
    background-color: #4c11e0;
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
    transition: all 0.2s ease;
  }
  
  .upgrade-button:hover {
    background-color: #570eea;
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(76, 17, 224, 0.3);
  }
  
  .near-limit .upgrade-button {
    background-color: #ff9800;
  }
  
  .near-limit .upgrade-button:hover {
    background-color: #f57c00;
    box-shadow: 0 2px 8px rgba(255, 152, 0, 0.3);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .subscription-banner {
      margin-left: 10px;
      margin-right: 10px;
      width: calc(100% - 20px);
    }
    
    .banner-title {
      font-size: 0.8rem;
    }
    
    .banner-message {
      font-size: 0.8rem;
    }
  }