/* Styles/togo.css - Enhanced with progressive text display, fullscreen layout, and mobile fixes */
.togo-page {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--navbar-height));
    background: linear-gradient(135deg, #0d111e 0%, #1f2937 100%);
    overflow: auto; /* Changed from hidden to auto to allow scrolling if needed */
    position: relative;
    z-index: 5;
    margin: 0;
    padding: 0;
  }
    
  /* Background gradient effect */
  .togo-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
      radial-gradient(circle at 20% 30%, rgba(79, 172, 254, 0.03) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(246, 211, 101, 0.03) 0%, transparent 50%);
    pointer-events: none;
    z-index: 1; /* Lower z-index to ensure it stays behind content */
  }
    
  .togo-container {
    display: flex;
    flex-direction: column;
    height: auto; /* Changed from 100% to auto to prevent forcing height */
    min-height: 100%; /* Ensure it still fills the page */
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
    position: relative;
    z-index: 5;
  }
    
  /* 3D Character Container - Reduced height */
  .canvas-container {
    position: relative;
    height: 35vh; /* Reduced from 45vh to give more space to chat */
    min-height: 200px; /* Reduced from 250px */
    max-height: 300px; /* Added max height to prevent taking too much space */
    background: rgba(13, 17, 30, 0.8);
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 0.75rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
    z-index: 5;
  }
    
  .model-error-overlay {
    position: absolute;
    bottom: 16px;
    left: 16px;
    background: rgba(0, 0, 0, 0.8);
    color: #ff6b6b;
    padding: 0.6rem 1.2rem;
    border-radius: 8px;
    font-size: 0.9rem;
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 107, 107, 0.3);
    z-index: 11; /* Above other elements */
  }
    
  /* Action buttons */
  .action-buttons {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    gap: 0.8rem;
    z-index: 15;
  }
    
  .action-button {
    background: rgba(17, 24, 39, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(4px);
  }
    
  .action-button:hover {
    background: rgba(30, 41, 59, 0.9);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
    
  .action-button.refresh:hover {
    transform: rotate(180deg) translateY(-2px);
  }
    
  .action-button:active {
    transform: translateY(0);
  }
    
  .action-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
    
  .action-button.speech.enabled {
    color: #4facfe;
    border-color: rgba(79, 172, 254, 0.3);
  }
    
  .action-button.speech.disabled {
    color: #ffffff;
  }
    
  .action-button.voice {
    color: #ffc107;
    border-color: rgba(255, 193, 7, 0.3);
  }
    
  .action-button.voice:hover {
    background: rgba(255, 193, 7, 0.2);
  }
    
  .action-button.clear {
    color: #ff6b6b;
    border-color: rgba(255, 107, 107, 0.2);
  }
    
  .action-button.clear:hover {
    background: rgba(255, 107, 107, 0.2);
  }
    
  .action-indicator {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }
    
  .loading-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4facfe;
    animation: pulse 1.5s infinite;
    box-shadow: 0 0 10px rgba(79, 172, 254, 0.6);
  }
    
  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0.5;
    }
  }
    
  /* Chat Container - Increased height */
  .chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: rgba(13, 17, 30, 0.8);
    border-radius: 12px;
    padding: 1.25rem;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    margin-bottom: 0.75rem;
    position: relative;
    z-index: 5;
    min-height: 300px; /* Increased from 250px */
    max-height: 55vh; /* Increased to give more space to chat */
    overflow: hidden;
    visibility: visible !important;
  }
    
  /* Enhanced Messages Box with scroll handling */
  .messages-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    height: auto;
    max-height: calc(100% - 120px);
    padding-right: 0.75rem;
    margin-bottom: 1.25rem; /* Increased spacing between messages and input */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
    position: relative;
    -webkit-overflow-scrolling: touch;
    visibility: visible !important; /* Force visibility */
  }
    
  .messages-box::-webkit-scrollbar {
    width: 6px;
  }
    
  .messages-box::-webkit-scrollbar-track {
    background: transparent;
  }
    
  .messages-box::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
  }
    
  /* Scroll down button */
  .scroll-down-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(79, 172, 254, 0.3);
    border: 1px solid rgba(79, 172, 254, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: pulseButton 2s infinite;
    z-index: 5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
  }
    
  .scroll-down-button:hover {
    background: rgba(79, 172, 254, 0.5);
    transform: translateY(-2px);
  }
    
  /* Subtle pulse animation for scroll button */
  @keyframes pulseButton {
    0% { box-shadow: 0 0 0 0 rgba(79, 172, 254, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(79, 172, 254, 0); }
    100% { box-shadow: 0 0 0 0 rgba(79, 172, 254, 0); }
  }
    
  /* Enhanced Message styling with better spacing and readability */
  .message {
    margin-bottom: 1rem; /* Increased from 0.75rem */
    padding: 1rem 1.2rem; /* Increased from 0.9rem 1rem */
    border-radius: 12px;
    max-width: 82%; /* Reduced from 85% to avoid edge proximity */
    line-height: 1.6;
    animation: fadeIn 0.3s ease;
    transition: all 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    display: block !important; /* Changed from flex to block for better compatibility */
    visibility: visible !important; /* Ensure visibility */
  }
    
  /* Fix for spacing between messages */
  .message + .message {
    margin-top: 1rem; /* Increased from 0.8rem */
  }
    
  /* Fix for when a new message is added */
  .message:last-child {
    margin-bottom: 1rem;
  }
    
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
    
  .message:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
    
  /* User message styling - improved contrast and spacing */
  .message.user {
    background: linear-gradient(120deg, rgba(79, 172, 254, 0.2) 0%, rgba(0, 242, 254, 0.15) 100%);
    color: white;
    align-self: flex-end;
    margin-left: auto;
    border-bottom-right-radius: 4px;
    border: 1px solid rgba(79, 172, 254, 0.3);
    font-size: 15px; /* Standardized font size */
    display: block !important;
  }
    
  /* Assistant message styling - improved contrast and readability */
  .message.assistant {
    background: rgba(36, 45, 66, 0.9); /* Darker for better contrast */
    color: #ffffff; /* Brighter text color */
    align-self: flex-start;
    border-bottom-left-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    font-size: 15px;
    max-width: 88%; /* Slightly wider for assistant messages */
    white-space: pre-line;
    max-height: none;
    letter-spacing: 0.01em; /* Slight letter spacing for readability */
    display: block !important; /* Ensure display */
    opacity: 1 !important; /* Ensure full opacity */
    visibility: visible !important; /* Force visibility */
  }
    
  .message.system {
    background: rgba(54, 54, 54, 0.4);
    color: #b0b0b0;
    font-style: italic;
    font-size: 0.9rem;
    align-self: center;
    max-width: 95%;
    text-align: center;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
    
  /* Message content styling with improved paragraph spacing */
  .message-content {
    width: 100%;
    white-space: pre-line; /* Preserve newlines from API response */
    max-height: 70vh; /* Prevent extremely long messages from taking over */
    overflow-y: auto; /* Add scrolling if needed */
    scrollbar-width: thin;
    display: block !important; /* Ensure content is displayed */
    visibility: visible !important; /* Force visibility */
  }
    
  /* Paragraph styling within messages */
  .message-content p,
  .message.assistant p {
    margin-bottom: 1rem; /* Increased from 0.9rem */
    line-height: 1.6;
  }
    
  .message-content p:last-child,
  .message.assistant p:last-child {
    margin-bottom: 0;
  }
    
  /* Support for proper formatting in the assistant's responses */
  .message.assistant h1,
  .message.assistant h2,
  .message.assistant h3,
  .message.assistant h4 {
    margin-top: 1.2rem;
    margin-bottom: 0.8rem;
    font-weight: 600;
    color: #ffffff;
  }
    
  .message.assistant h1 { font-size: 1.4rem; }
  .message.assistant h2 { font-size: 1.25rem; }
  .message.assistant h3 { font-size: 1.15rem; }
  .message.assistant h4 { font-size: 1.05rem; }
    
  /* Better lists formatting */
  .message.assistant ul,
  .message.assistant ol {
    margin: 0.8rem 0 1rem 1.2rem;
    padding-left: 0.8rem;
  }
    
  .message.assistant li {
    margin-bottom: 0.5rem;
    padding-left: 0.3rem;
  }
    
  /* Better code block styling */
  .message.assistant pre {
    background: rgba(0, 0, 0, 0.3);
    padding: 0.8rem;
    margin: 0.8rem 0;
    border-radius: 6px;
    overflow-x: auto;
    font-family: monospace;
    font-size: 0.9em;
    white-space: pre;
  }
    
  .message.assistant code {
    background: rgba(0, 0, 0, 0.3);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
    font-size: 0.9em;
    white-space: pre-wrap;
  }
    
  /* Table styling within messages */
  .message.assistant table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    overflow-x: auto;
    display: block;
  }
    
  .message.assistant th,
  .message.assistant td {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem;
    text-align: left;
  }
    
  .message.assistant th {
    background-color: rgba(0, 0, 0, 0.2);
    font-weight: 600;
  }
    
  .message.assistant tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.1);
  }
    
  /* Progressive text animation */
  .progressive-text {
    animation: progressiveReveal 0.4s ease-out;
    animation-fill-mode: both;
    display: block !important; /* Force display */
    visibility: visible !important; /* Ensure visibility */
    opacity: 1 !important; /* Full opacity */
  }
  
  .progressive-text p {
    animation: progressiveReveal 0.4s ease-out;
    animation-fill-mode: both;
    display: block !important; /* Force paragraph display */
    visibility: visible !important; /* Ensure paragraph visibility */
  }
    
  /* Handle different paragraph animations in progressive text */
  .progressive-text p:nth-child(1) { animation-delay: 0.1s; }
  .progressive-text p:nth-child(2) { animation-delay: 0.2s; }
  .progressive-text p:nth-child(3) { animation-delay: 0.3s; }
  .progressive-text p:nth-child(4) { animation-delay: 0.4s; }
  .progressive-text p:nth-child(5) { animation-delay: 0.5s; }
    
  /* Improved Typing Animation */
  .message.assistant.typing {
    background: rgba(36, 45, 66, 0.6);
    padding: 0.7rem 1rem;
  }
    
  .typing-animation {
    display: inline-flex;
    gap: 5px;
    padding: 4px 0;
    align-items: center;
  }
    
  .typing-animation .dot {
    width: 7px;
    height: 7px;
    background: #fff;
    border-radius: 50%;
    opacity: 0.7;
    animation: typingBounce 1.2s infinite ease-in-out;
  }
    
  .typing-animation .dot:nth-child(1) {
    animation-delay: 0s;
  }
    
  .typing-animation .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
    
  .typing-animation .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
    
  @keyframes typingBounce {
    0%, 60%, 100% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-5px);
    }
  }
    
  /* Suggested Questions - more compact */
  .suggested-questions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem; /* Slightly increased from 0.5rem */
    margin-bottom: 0.85rem; /* Increased from 0.75rem */
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0.3rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
    
  .suggested-questions::-webkit-scrollbar {
    display: flex; /* Hide scrollbar in Chrome/Safari/Webkit */
  }
    
  .suggested-question {
    background: rgba(36, 45, 66, 0.7); /* Darker for better visibility */
    border: 1px solid rgba(79, 172, 254, 0.25); /* Slightly stronger border */
    color: white;
    padding: 0.65rem 1.1rem;
    border-radius: 30px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: none;
    flex-shrink: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
    
  .suggested-question:hover {
    background: rgba(79, 172, 254, 0.25);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
    
  .suggested-question:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
    
  .suggested-question:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
    
  /* Question icon for suggested questions */
  .question-icon {
    display: inline-block;
    margin-right: 6px;
    opacity: 0.7;
  }
    
  /* Enhanced Input Form - More compact */
  .message-input-form {
    position: relative; /* Change from sticky to relative */
    display: flex;
    gap: 0.75rem;
    background: rgba(13, 17, 30, 0.8);
    padding: 0.6rem 0;
    margin-top: 0.5rem;
    z-index: 10;
    min-height: 54px; /* Slightly increased for better touch targets */
  }
    
  .input-wrapper {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
  }
    
  .message-input {
    flex: 1;
    background: rgba(36, 45, 66, 0.7); /* Slightly darker for better contrast */
    border: 1px solid rgba(255, 255, 255, 0.15); /* Brighter border */
    color: white;
    padding: 1rem 2.5rem 1rem 1.25rem;
    border-radius: 10px;
    resize: none;
    min-height: 54px; /* Increased from 50px */
    max-height: 120px; /* Increased from 100px */
    outline: none;
    transition: all 0.2s;
    font-family: inherit;
    font-size: 15px; /* Consistent with message text size */
    width: 100%;
    line-height: 1.4;
  }
    
  .message-input:focus {
    border-color: rgba(79, 172, 254, 0.6); /* Brighter focus state */
    box-shadow: 0 0 0 2px rgba(79, 172, 254, 0.15);
  }
    
  .message-input:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
    
  /* Enhanced Send Button */
  .send-button {
    background: linear-gradient(45deg, #4facfe 0%, #00f2fe 100%);
    color: white;
    border: none;
    padding: 0 1.5rem;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    white-space: nowrap;
    font-size: 0.95rem;
    min-width: 100px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
    
  .send-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(79, 172, 254, 0.4);
  }
    
  .send-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(79, 172, 254, 0.2);
  }
    
  .send-button:disabled {
    opacity: 0.6;
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
  }
    
  /* Voice input button */
  .mic-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: #aaa;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    
  .mic-button:hover {
    color: #4facfe;
    background: rgba(79, 172, 254, 0.1);
  }
    
  .mic-button.active {
    color: #ff6b6b;
    animation: pulse 1.5s infinite;
  }
    
  /* Voice Selector and Menu */
  .voice-selector {
    position: relative;
  }
    
  .voice-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background: rgba(13, 17, 30, 0.95);
    border-radius: 10px;
    padding: 1rem;
    min-width: 150px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(79, 172, 254, 0.2);
    z-index: 999;
    transform-origin: top right;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0.95);
    display: block;
    pointer-events: none;
  }
    
  .voice-menu.visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
    
  @keyframes scaleIn {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
    
  .voice-menu-title {
    color: #fff;
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
    
  .voice-options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
    
  .voice-option {
    background: rgba(30, 41, 59, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: #fff;
    padding: 0.5rem;
    border-radius: 6px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.2s;
    text-align: left;
  }
    
  .voice-option:hover {
    background: rgba(79, 172, 254, 0.2);
    transform: translateY(-1px);
  }
    
  .voice-option.active {
    background: rgba(79, 172, 254, 0.3);
    border-color: rgba(79, 172, 254, 0.4);
    color: #fff;
  }
    
  .voice-divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 0.5rem 0;
  }
    
  .voice-provider-toggle {
    background: rgba(30, 41, 59, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 0.5rem;
    border-radius: 6px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.2s;
    text-align: center;
    margin-top: 0.5rem;
  }
    
  .voice-provider-toggle:hover {
    background: rgba(79, 172, 254, 0.2);
  }
    
  .spin {
    animation: spin 1.5s linear infinite;
  }
    
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
    
  /* Subscription Modal Styles */
  .subscription-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(8px);
    animation: fadeIn 0.3s ease;
  }
    
  .subscription-modal-content {
    background: linear-gradient(135deg, #1f2937 0%, #0d111e 100%);
    border-radius: 16px;
    width: 95%;
    max-width: 850px;
    padding: 2.5rem;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  }
    
  .subscription-modal h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: linear-gradient(90deg, #4facfe 0%, #00f2fe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
    
  .limit-message {
    text-align: center;
    margin-bottom: 2.5rem;
    font-size: 1.15rem;
    line-height: 1.6;
    color: #e0e0e0;
  }
    
  .subscription-options {
    display: flex;
    gap: 2.5rem;
    justify-content: center;
    margin-bottom: 2.5rem;
  }
    
  .subscription-option {
    background: rgba(36, 45, 66, 0.6);
    border-radius: 12px;
    padding: 2rem;
    flex: 1;
    max-width: 350px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }
    
  .subscription-option:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
  }
    
  .subscription-option.premium {
    background: rgba(79, 172, 254, 0.15);
    border-color: rgba(79, 172, 254, 0.3);
    position: relative;
    overflow: hidden;
  }
    
  .subscription-option.premium::before {
    content: "RECOMMENDED";
    position: absolute;
    top: 12px;
    right: -28px;
    background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%);
    color: white;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    transform: rotate(45deg);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }

    .subscription-option.premium::before {
        content: "RECOMMENDED";
        position: absolute;
        top: 12px;
        right: -28px;
        background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%);
        color: white;
        font-size: 0.7rem;
        padding: 5px 30px;
        transform: rotate(45deg);
        font-weight: bold;
        letter-spacing: 1px;
      }
        
      .subscription-option h3 {
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
        text-align: center;
      }
        
      .subscription-option .price {
        font-size: 2rem;
        margin-bottom: 1.75rem;
        text-align: center;
        font-weight: bold;
        color: #4facfe;
      }
        
      .subscription-option ul {
        margin-bottom: 2rem;
        padding-left: 1.5rem;
      }
        
      .subscription-option li {
        margin-bottom: 0.75rem;
        font-size: 1rem;
        position: relative;
        padding-left: 0.5rem;
      }
        
      .subscription-option li::before {
        content: "✓";
        position: absolute;
        left: -1.25rem;
        color: #4facfe;
        font-weight: bold;
      }
        
      .upgrade-button {
        display: block;
        background: linear-gradient(45deg, #4facfe 0%, #00f2fe 100%);
        color: white;
        text-align: center;
        padding: 1rem;
        border-radius: 10px;
        text-decoration: none;
        font-weight: 500;
        transition: all 0.2s ease;
        font-size: 1.05rem;
      }
        
      .upgrade-button:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 24px rgba(79, 172, 254, 0.4);
      }
        
      .upgrade-button.premium {
        background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%);
      }
        
      .close-button {
        display: block;
        width: 100%;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: white;
        padding: 1rem;
        border-radius: 10px;
        margin-top: 1.5rem;
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: 1rem;
      }
        
      .close-button:hover {
        background: rgba(255, 255, 255, 0.05);
      }
        
      /* Custom scrollbar for Webkit browsers */
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
        
      ::-webkit-scrollbar-track {
        background: rgba(13, 17, 30, 0.3);
        border-radius: 10px;
      }
        
      ::-webkit-scrollbar-thumb {
        background: rgba(79, 172, 254, 0.5);
        border-radius: 10px;
      }
        
      ::-webkit-scrollbar-thumb:hover {
        background: rgba(79, 172, 254, 0.7);
      }
        
      /* Ensure the chat interface always fits without causing overflow */
      html, body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
        
      /* Media queries for better responsive layout */
      @media (min-height: 800px) {
        /* For taller screens, balance space between 3D model and chat */
        .canvas-container {
          height: 30vh;
          max-height: 350px;
        }
        
        .chat-container {
          min-height: 350px;
          max-height: 60vh;
        }
      }
        
      @media (max-height: 700px) {
        /* For shorter screens, make everything more compact */
        .coach-metrics-panel.collapsed {
          max-height: 100px; /* Reduced further */
        }
        
        .canvas-container {
          height: 30vh; /* Reduced from 40vh */
          min-height: 180px; /* Reduced from 200px */
          max-height: 250px;
        }
        
        .chat-container {
          min-height: 250px;
          max-height: 60vh; /* Increased to give more priority to chat */
        }
        
        .message {
          margin-bottom: 0.5rem;
          padding: 0.75rem 0.85rem;
        }
      }
        
      /* Responsive styles for different widths */
      @media (max-width: 992px) {
        .togo-container {
          padding: 0.85rem;
        }
        
        .subscription-options {
          flex-direction: column;
          align-items: center;
          gap: 1.5rem;
        }
        
        .subscription-option {
          max-width: 100%;
          width: 100%;
        }
      }
        
      @media (max-width: 768px) {
        /* Mobile-specific improvements */
        .coach-metrics-panel.collapsed {
          max-height: 140px;
        }
        
        /* Adjust 3D canvas size */
        .canvas-container {
          height: 30vh;
          min-height: 180px;
          max-height: 250px;
        }
        
        .message {
          max-width: 92%;
          padding: 0.9rem 1rem;
        }
        
        /* Ensure text is readable */
        .message.assistant {
          font-size: 15px;
        }
        
        .subscription-modal-content {
          padding: 1.5rem;
        }
        
        .send-button {
          min-width: 80px;
          padding: 0 1rem;
        }
      }
        
      @media (max-width: 480px) {
        /* Extra adjustments for very small screens */
        .togo-container {
          padding: 0.6rem;
        }
        
        .chat-container {
          padding: 0.85rem;
        }
        
        .message {
          margin-bottom: 0.8rem;
          max-width: 95%;
        }
        
        /* Ensure assistant messages are clearly visible and well-formatted */
        .message.assistant {
          padding: 1rem;
          font-size: 15px;
          line-height: 1.5;
          background: rgba(36, 45, 66, 0.95); /* Even darker for better contrast */
        }
        
        .message-input {
          padding: 0.75rem 2.5rem 0.75rem 0.75rem;
        }
        
        .canvas-container {
          min-height: 150px;
          max-height: 200px;
        }
        
        .send-button {
          min-width: 70px;
        }
      }
        
      /* Additional tweaks for landscape mode on mobile */
      @media (max-height: 500px) and (orientation: landscape) {
        .coach-metrics-panel.collapsed {
          max-height: 90px;
        }
        
        .canvas-container {
          height: 30vh;
          min-height: 120px;
          max-height: 150px;
        }
        
        .chat-container {
          min-height: 180px;
          max-height: 65vh; /* Give even more priority to chat in landscape */
        }
        
        .suggested-questions {
          margin-bottom: 0.5rem;
        }
        
        .message-input-form {
          padding: 0.25rem 0;
        }
        
        .message-input {
          min-height: 40px;
        }
      }
        
      /* Fix for Firefox overflow issues */
      @-moz-document url-prefix() {
        .togo-container {
          height: calc(100vh - 20px);
        }
      }
        
      /* Fix for iOS Safari */
      @supports (-webkit-touch-callout: none) {
        .page-content {
          /* Use min-height instead of height for iOS */
          min-height: -webkit-fill-available;
          height: auto;
        }
      
        .togo-page {
          height: -webkit-fill-available;
        }
          
        /* Add extra bottom padding for iOS safe area */
        .message-input-form {
          padding-bottom: calc(0.5rem + env(safe-area-inset-bottom, 0));
        }
      }
        
      /* For extra tall displays, set maximum heights to prevent excessive stretching */
      @media (min-height: 1200px) {
        .scrollable-page {
          max-width: 1200px;
          margin: 0 auto;
        }
      }
        
      /* Landscape mode on mobile */
      @media (max-height: 500px) and (orientation: landscape) {
        .togo-page {
          height: auto;
          min-height: calc(100vh - var(--navbar-height));
          overflow-y: auto;
        }
        
        .coach-metrics-panel.collapsed {
          max-height: 80px;
        }
        
        .canvas-container {
          height: 25vh; /* Even smaller in landscape */
          min-height: 120px;
          max-height: 150px;
        }
        
        /* Give more priority to the chat */
        .chat-container {
          min-height: 200px;
          max-height: 70vh;
        }
      }
      
      /* Animation for progressive reveal */
      @keyframes progressiveReveal {
        from { opacity: 0; transform: translateY(5px); }
        to { opacity: 1; transform: translateY(0); }
      }

      /* Add this to your existing CSS file */
      .data-update-indicator {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 12px;
        color: #4caf50;
        background-color: rgba(76, 175, 80, 0.1);
        padding: 4px 8px;
        border-radius: 12px;
        margin-left: 8px;
        animation: fadeIn 0.3s ease-in-out;
      }
      
      .data-update-indicator svg {
        color: #4caf50;
      }
      
      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }