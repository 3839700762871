/* Enhanced CSS for workout and meal plan preferences modals */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 10px;
    width: 90%;
    max-width: 550px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
    padding: 25px;
    position: relative;
}

.modal-content h2 {
    color: #4c11e0;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
}

.modal-content h3 {
    color: #570eea;
    margin: 15px 0 10px;
    font-size: 1.3rem;
}

.loading {
    text-align: center;
    padding: 40px 20px;
}

.spinner {
    margin: 20px auto;
    width: 50px;
    height: 50px;
    border: 5px solid rgba(76, 17, 224, 0.2);
    border-radius: 50%;
    border-top-color: #4c11e0;
    animation: spin 1s linear infinite;
}

.preferences-form {
    display: flex;
    flex-direction: column;
}

.form-section {
    background-color: #f8f8fe;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #e6e6fa;
}

.preferences-form label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    color: #333;
}

.preferences-form input[type="checkbox"] {
    margin-right: 8px;
    transform: scale(1.2);
}

.preferences-form input[type="text"],
.preferences-form input[type="number"],
.preferences-form select,
.preferences-form .react-datepicker-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-top: 5px;
    font-size: 14px;
    background-color: white;
}

.preferences-form input[type="range"] {
    width: 100%;
    margin-top: 10px;
}

.preferences-form input[type="range"] + span {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    color: #4c11e0;
    font-weight: 500;
}

.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
}

.form-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
}

.form-buttons button {
    padding: 12px 24px;
    background-color: #4c11e0;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    transition: background-color 0.3s, transform 0.2s;
}

.form-buttons button:hover {
    background-color: #570eea;
    transform: translateY(-2px);
}

.form-buttons button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    transform: none;
}

.form-buttons button:nth-child(2) {
    background-color: #f44336;
}

.form-buttons button:nth-child(2):hover {
    background-color: #d32f2f;
}

/* Special highlight for the previous week selection area */
.form-section:first-child {
    background-color: #f0f8ff;
    border-left: 4px solid #4c11e0;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .modal-content {
        padding: 15px;
        width: 95%;
    }
    
    .checkbox-grid {
        grid-template-columns: 1fr;
    }
    
    .form-buttons button {
        padding: 10px 20px;
        font-size: 14px;
    }
}