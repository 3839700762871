/* About.css */
.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #333;
    font-family: 'Arial', sans-serif;
}

.about-container h1 {
    color: #4c11e0;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
}

.about-section {
    margin-bottom: 2rem;
}

.about-section h2 {
    color: #570eea;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.about-section p {
    line-height: 1.6;
    margin-bottom: 1rem;
}

.feature-card {
    background-color: #f9f5ff;
    border-left: 4px solid #6a19ff;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0 5px 5px 0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(106, 25, 255, 0.2);
}

.feature-card h3 {
    color: #4c11e0;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
}

.about-section ol, .about-section ul {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

.about-section li {
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.policy-links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
}

.policy-links a {
    color: #4c11e0;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.policy-links a:hover {
    color: #6a19ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .about-container {
        padding: 1.5rem;
        margin: 1rem;
    }

    .about-container h1 {
        font-size: 2rem;
    }

    .about-section h2 {
        font-size: 1.5rem;
    }

    .feature-card h3 {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .about-container {
        padding: 1rem;
        margin: 0.5rem;
    }

    .about-container h1 {
        font-size: 1.8rem;
    }

    .about-section h2 {
        font-size: 1.3rem;
    }

    .feature-card {
        padding: 0.8rem;
    }

    .policy-links {
        flex-direction: column;
        gap: 0.5rem;
    }
}