/* Enhanced CSS for WorkoutDetailsModal */
.modal-content {
    background: white;
    padding: 25px;
    border-radius: 12px;
    max-width: 650px;
    width: 90%;
    margin: auto;
    max-height: 85vh;
    overflow-y: auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content h2 {
    color: #4c11e0;
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
}

.workout-metadata {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.workout-section {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.workout-section h3 {
    color: #570eea;
    margin-top: 0;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e0e0e0;
}

.workout-status {
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
}

.status-scheduled {
    background-color: #e3f2fd;
    color: #1976d2;
}

.status-completed {
    background-color: #e8f5e9;
    color: #388e3c;
}

.exercise-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.exercise-item {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: white;
    transition: box-shadow 0.3s ease;
}

.exercise-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.exercise-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #4c11e0;
}

.exercise-stats {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 10px;
}

.exercise-stats span {
    background-color: #f3f4f6;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
}

.exercise-notes {
    font-style: italic;
    font-size: 14px;
    padding: 8px 0;
    color: #666;
}

.completion-results {
    margin-top: 12px;
    padding: 10px;
    background-color: #e8f5e9;
    border-radius: 6px;
}

.completion-results h4 {
    margin-top: 0;
    margin-bottom: 8px;
    color: #388e3c;
    font-size: 15px;
}

.exercise-details {
    position: relative;
}

.exercise-field {
    margin-bottom: 12px;
}

.exercise-field label {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
    color: #444;
}

.exercise-field input, 
.exercise-field textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.exercise-field textarea {
    min-height: 80px;
    resize: vertical;
}

.tracking-fields {
    padding: 12px;
    background-color: #f0f7ff;
    border-radius: 8px;
    margin-top: 10px;
}

.tracking-fields h4 {
    margin-top: 0;
    margin-bottom: 12px;
    color: #1976d2;
    font-size: 15px;
}

.modal-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 25px;
}

.modal-buttons button {
    padding: 10px 16px;
    border: none;
    border-radius: 6px;
    background-color: #6b7280;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.modal-buttons button:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.modal-buttons button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.modal-buttons .primary-button {
    background-color: #4c11e0;
}

.modal-buttons .delete-button {
    background-color: #ef4444;
}

.modal-buttons .active-mode {
    background-color: #4c11e0;
    position: relative;
}

.modal-buttons .active-mode::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background-color: #4c11e0;
    border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .modal-content {
        padding: 15px;
    }
    
    .exercise-stats {
        gap: 8px;
    }
    
    .exercise-stats span {
        font-size: 12px;
    }
    
    .modal-buttons {
        flex-direction: column;
    }
    
    .modal-buttons button {
        width: 100%;
        margin-bottom: 8px;
    }
}