.compliance-checks .compliance-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.compliance-checks input[type="checkbox"] {
    appearance: none; /* Remove default checkbox styling */
    width: 20px; /* Define width */
    height: 20px; /* Define height */
    border: 2px solid #007bff; /* Add border to make it visible */
    border-radius: 4px; /* Optional: round edges for the checkbox */
    cursor: pointer;
    position: relative; /* Relative for inner checkmark */

    /* Adding a transition for a smoother effect */
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.compliance-checks input[type="checkbox"]:checked {
    background-color: #007bff; /* Change background when checked */
    border-color: #0056b3; /* Change border color when checked */
}

.compliance-checks input[type="checkbox"]:checked::after {
    content: '✔'; /* Adding a checkmark when selected */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Checkmark color */
    font-size: 12px;
}

.compliance-checks a {
    color: #007bff;
    text-decoration: underline;
}

.compliance-checks a:hover {
    color: #0056b3;
    text-decoration: none;
}

/* Add these styles to your Register.css file */

.verification-message {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.verification-message h2 {
    color: #4c11e0;
    margin-bottom: 20px;
}

.verification-message h3 {
    color: #333;
    margin-bottom: 15px;
}

.verification-message p {
    color: #555;
    line-height: 1.5;
    margin-bottom: 15px;
}

.verification-message .important-note {
    color: #e41b17;
    font-weight: bold;
    font-size: 16px;
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #e41b17;
    border-radius: 5px;
    background-color: rgba(228, 27, 23, 0.05);
}

.verification-message .email-icon {
    font-size: 48px;
    margin: 20px 0;
}

.verification-actions {
    margin-top: 25px;
}

.verification-message .primary-button {
    background-color: #4c11e0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.verification-message .primary-button:hover {
    background-color: #3b0dbe;
}

.verification-help {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.verification-help p {
    font-size: 14px;
    color: #777;
}

/* Add animation to the email icon */
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.verification-message .email-icon {
    animation: pulse 2s infinite;
}