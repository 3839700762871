/* Updated Navbar.module.css with better mobile responsiveness */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--text-dark);
    color: var(--text-light);
    padding: 0.5em 1em;
    position: relative;
}

.navbar h1 {
    font-size: 1.5em;
    margin: 0;
}

.navbar ul {
    list-style-type: none;
    display: flex;
    gap: 0.8em;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.navbar li {
    cursor: pointer;
    transition: color 0.2s;
    padding: 5px 8px;
    white-space: nowrap;
}

.navbar li:hover {
    color: var(--secondary-blue);
}

.logoutLink {
    color: #ff6b6b;
}

.logoutLink:hover {
    color: #ff4757;
}

.menuButton {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px;
}

.menuOpen {
    display: flex;
}

/* Mobile Navbar Adjustments */
@media screen and (max-width: 768px) {
    .navbar {
        flex-wrap: wrap;
    }
    
    .navbar ul {
        width: 100%;
        justify-content: space-around;
        padding-top: 0.5em;
        gap: 0.2em;
    }
    
    .navbar li {
        font-size: 0.9em;
        padding: 4px 6px;
    }
}

/* Small Mobile Screens */
@media screen and (max-width: 480px) {
    .menuButton {
        display: block;
    }
    
    .navbar ul {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        padding: 0.5em 0;
    }
    
    .navbar ul.menuOpen {
        display: flex;
    }
    
    .navbar li {
        padding: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }
    
    .navbar li:last-child {
        border-bottom: none;
    }
}

/* Fix for notched devices */
@supports (padding: max(0px)) {
    .navbar {
        padding-top: max(0.5em, env(safe-area-inset-top));
        padding-left: max(1em, env(safe-area-inset-left));
        padding-right: max(1em, env(safe-area-inset-right));
    }
}

/* Add these styles to your Navbar.module.css */
.loadingIndicator {
    color: #ffffff;
    font-size: 14px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.loadingIndicator::after {
    content: "";
    width: 16px;
    height: 16px;
    margin-left: 8px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Add these styles to your app.css or responsive.css */
.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    font-size: 1.2rem;
    color: #4c11e0;
    text-align: center;
    padding: 20px;
}

.loading-screen::after {
    content: "";
    width: 24px;
    height: 24px;
    margin-left: 12px;
    border: 3px solid #4c11e0;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}