/* Updated HydrationGoalTracker.css */
.hydration-tracker {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    max-width: 350px;
    margin: 0 auto;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .tracker-header {
    color: #0277bd;
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0,0,0,0.1);
  }
  
  .tracker-header svg {
    margin-right: 8px;
    font-size: 1.6rem;
  }
  
  /* Updated unit toggle button styling */
.unit-toggle-btn {
    background-color: #e0e0e0;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    padding: 10px 16px;
    width: 100%;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #444;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  }
  
  .unit-toggle-btn:hover {
    background-color: #d0d0d0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  }
  
  .unit-toggle-btn:active {
    background-color: #c0c0c0;
    transform: translateY(1px);
  }
  
  /* Add this for better contrast in the current unit display */
  .current-unit {
    font-size: 14px;
    color: #555;
    font-weight: 500;
    font-style: italic;
    text-align: center;
  }
  
  .date-input {
    margin-bottom: 20px;
  }
  
  .date-input input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  }
  
  .progress-section {
    margin-bottom: 24px;
  }
  
  .progress-text {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #333;
  }
  
  .progress-bar-container {
    height: 12px;
    background-color: #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .progress-bar {
    height: 100%;
    background: linear-gradient(to right, #2196f3, #03a9f4);
    transition: width 0.3s ease;
  }
  
  .quick-add-section {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 24px;
  }
  
  .quick-add-btn {
    flex: 1;
    padding: 12px 8px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  }
  
  .quick-add-btn:hover {
    background-color: #1976d2;
  }
  
  .recent-entries {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 24px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  }
  
  .entries-header {
    font-weight: 600;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    font-size: 1rem;
    color: #333;
  }
  
  .entry-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    border-bottom: 1px solid #eeeeee;
    font-size: 0.95rem;
  }
  
  .entry-item:last-child {
    border-bottom: none;
  }
  
  .entry-amount {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #333;
  }
  
  .entry-icon {
    color: #2196f3;
    font-size: 1.1rem;
  }
  
  .entry-time {
    color: #555;
    font-weight: 500;
  }
  
  .modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .cancel-btn, .save-btn {
    padding: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    font-size: 1rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  }
  
  .cancel-btn {
    background-color: #757575;
    color: white;
  }
  
  .save-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #616161;
  }
  
  .save-btn:hover {
    background-color: #43a047;
  }