.hydration-challenge {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 15px;
    margin-top: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .hydration-challenge.loading {
    text-align: center;
    padding: 30px;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .challenge-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 8px;
  }
  
  .trophy-icon {
    color: gold;
    margin-right: 10px;
    font-size: 20px;
  }
  
  .challenge-header h3 {
    margin: 0;
    font-size: 16px;
    color: white;
  }
  
  .challenge-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .challenge-progress {
    width: 100%;
  }
  
  .progress-bar-container {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 20px;
    overflow: hidden;
    position: relative;
  }
  
  .progress-bar {
    background: linear-gradient(to right, #3498db, #2ecc71);
    height: 100%;
    border-radius: 10px;
    transition: width 0.3s ease;
  }
  
  .progress-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  }
  
  .challenge-status {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .completed-status {
    display: flex;
    align-items: center;
    color: #2ecc71;
  }
  
  .incomplete-status {
    display: flex;
    align-items: center;
    color: white;
  }
  
  .check-icon, .water-icon {
    margin-right: 8px;
  }
  
  .challenge-streak {
    font-size: 13px;
    color: #f39c12;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  /* Animation for completing challenge */
  @keyframes celebrate {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  .challenge-completed {
    animation: celebrate 0.5s ease;
  }