/* SubscriptionPage.css */
.subscription-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .subscription-title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
    color: #4c11e0;
  }
  
  /* Current Subscription Card */
  .current-subscription-card {
    background: white;
    border-radius: 12px;
    padding: 25px;
    margin-bottom: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .current-subscription-card h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .subscription-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .current-plan {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .current-plan .capitalize {
    text-transform: capitalize;
    font-weight: 600;
    color: #4c11e0;
  }
  
  .renewal-date {
    font-size: 0.9rem;
    color: #666;
  }
  
  .manage-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .manage-button:hover {
    background-color: #e0e0e0;
  }
  
  .manage-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* Usage Meters */
  .usage-meters {
    margin-top: 25px;
  }
  
  .usage-meters h3 {
    font-size: 1.1rem;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .meter-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .meter {
    width: 100%;
  }
  
  .meter-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 0.95rem;
  }
  
  .meter-bar {
    height: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .meter-progress {
    height: 100%;
    border-radius: 5px;
    transition: width 0.3s ease;
  }
  
  .workout-meter {
    background-color: #4c11e0;
  }
  
  .meal-meter {
    background-color: #00b4d8;
  }
  
  .coach-meter {
    background-color: #10b981;
  }
  
  /* Loading State */
  .loading-indicator {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  
  /* Subscription Cards */
  .subscription-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1.2fr;
    gap: 25px;
    margin-bottom: 50px;
  }
  
  .subscription-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.2s;
    }