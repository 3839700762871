/* === Colors & Global Styles === */
:root {
  --primary-purple: #4c11e0;
  --secondary-purple: #89729E;
  --primary-blue: #634bb0;
  --secondary-blue: #7cade5;
  --background-light: #F7F7F7;
  --text-dark: #333;
  --text-light: #FFF;
  --border-color: #E5E5E5;
  --shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  --navbar-height: 48px; /* Define navbar height as a variable */
}

/* Base responsive viewport settings */
* {
  box-sizing: border-box;
}

/* Global responsive adjustments */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: var(--background-light);
  color: var(--text-dark);
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: auto; /* Allow vertical scrolling by default */
}

#root, .app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto; /* Allow content to scroll */
}

/* Page content container to account for navbar */
.page-content {
  padding-top: var(--navbar-height);
  min-height: calc(100vh - var(--navbar-height));
  width: 100%;
  flex: 1;
  position: relative;
  overflow-y: auto; /* Allow vertical scrolling */
}

/* Make sure navbar is consistently styled */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  z-index: 100;
  background-color: #0d111e;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
}

a:hover {
  color: var(--primary-blue);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
}

/* Loading screen */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
}

/* === Login & Register Forms === */
.login-container, .register-container {
  width: 100%;
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.login-container form, .register-container form {
  width: 100%;
  text-align: center;
}

.login-container input, .register-container input,
.login-container button, .register-container button {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  align-self: center;
}

/* === General Page Containers === */
/* These are generic containers that can be used by any page */
.page-container {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* === Forms and Input Fields === */
input, select, textarea, button {
  font-size: 16px; /* Prevents iOS zoom on focus */
}

button[type="submit"] {
  background-color: var(--primary-purple);
  color: var(--text-light);
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 25px;
  width: auto;
  font-size: 1rem;
  transition: background-color 0.3s;
}

/* === Compliance Documents Component === */
.compliance-documents a {
  color: #007bff;
  text-decoration: underline;
  margin-right: 10px;
}
.compliance-documents {
  margin-bottom: 20px;
}

/* === Accept Terms Component === */
.accept-terms-container {
  width: 80%;
  max-width: 500px;
  margin: 40px auto;
  padding: 40px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: var(--shadow);
  text-align: left;
}

.accept-terms-container h2 {
  color: var(--primary-purple);
  margin-bottom: 20px;
  align-items: center;
}

.compliance-documents {
  text-align: left;
  display: block;
}

.compliance-documents label input[type="checkbox"] {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.compliance-documents label {
  margin-right: 10px;
  flex-shrink: 0;
  align-items: left;
}

.compliance-documents label a {
  color: var(--primary-blue);
  text-decoration: underline;
  margin-left: 5px;
}

/* === Fix for iOS Safari viewport height issues === */
@supports (-webkit-touch-callout: none) {
  .page-content, .togo-page, .profile-container, .tracker-container {
      height: auto;
      min-height: -webkit-fill-available;
  }
}

/* === Fix for notched iPhones === */
@supports (padding: max(0px)) {
  .page-content {
      padding-left: max(1rem, env(safe-area-inset-left));
      padding-right: max(1rem, env(safe-area-inset-right));
      padding-bottom: max(1rem, env(safe-area-inset-bottom));
  }
  
  .navbar {
      padding-left: max(1rem, env(safe-area-inset-left));
      padding-right: max(1rem, env(safe-area-inset-right));
      padding-top: max(0, env(safe-area-inset-top));
  }
}

/* === Media Queries === */
/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .page-container {
      padding: 1.5rem;
  }
}

/* Larger screens */
@media (min-width: 1024px) {
  .page-container {
      padding: 2rem;
  }
}

/* Small screens height adjustments */
@media screen and (max-height: 600px) {
  .page-content {
      padding-top: calc(var(--navbar-height) - 8px);
  }
  
  .navbar {
      height: calc(var(--navbar-height) - 8px);
  }
}

/* === For pages that need full viewport height and no scrolling === */
.full-height-page {
  height: calc(100vh - var(--navbar-height));
  overflow: hidden;
}

/* === For pages that need scrolling === */
.scrollable-page {
  min-height: calc(100vh - var(--navbar-height));
  height: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  padding-bottom: max(20px, env(safe-area-inset-bottom));
}