/* StepsChallenge.css - Updated */
.steps-challenge {
  background-color: #2c2c2e;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  color: white;
}

.steps-challenge .challenge-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.steps-challenge .trophy-icon {
  color: #ffd700;
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.steps-challenge h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  flex-grow: 1;
}

.steps-challenge .tracking-indicator {
  font-size: 0.8rem;
  color: #ccc;
}

.steps-challenge .tracking-active {
  display: flex;
  align-items: center;
}

.steps-challenge .pulse-dot {
  width: 8px;
  height: 8px;
  background-color: #4cd964;
  border-radius: 50%;
  margin-right: 5px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

.steps-challenge .challenge-progress {
  margin: 0.75rem 0;
}

.steps-challenge .progress-bar-container {
  height: 10px;
  background-color: #3a3a3c;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px; /* Added space between bar and text */
}

.steps-challenge .progress-bar {
  height: 100%;
  background: linear-gradient(to right, #f6d365 0%, #fda085 100%);
  border-radius: 5px;
  transition: width 0.3s ease;
}

/* Fix for the progress text - Move it below the bar instead of overlaying */
.steps-challenge .progress-text {
  font-size: 0.9rem;
  text-align: right;
  margin-top: 0.25rem;
  color: #f0f0f0;
  display: block;
  position: relative;
  width: 100%;
}

.steps-challenge .challenge-status {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.steps-challenge .completed-status,
.steps-challenge .incomplete-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.steps-challenge .check-icon {
  color: #4cd964;
}

.steps-challenge .steps-icon {
  color: #fda085;
}

.steps-challenge .challenge-streak {
  font-size: 0.85rem;
  text-align: right;
  color: #ffd700;
}

.steps-challenge.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}