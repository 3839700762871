.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 300px;
    text-align: center;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.modal-content h2 {
    color: #333;
}

.modal-content p {
    margin-top: 10px;
    margin-bottom: 20px;
    color: #666;
}

.modal-content button {
    margin-top: 10px;
    background-color: #6B5B95; /* Primary purple color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.modal-content button:hover {
    background-color: #594381;
}
