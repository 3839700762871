/* Styles/MeasurementTrackers.css */
.measurement-form {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #555;
    background-color: #333;
    color: #fff;
  }
  
  .form-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .save-btn, .cancel-btn {
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
  }
  
  .save-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .cancel-btn {
    background-color: #f44336;
    color: white;
  }
  
  .history-toggle, .history-button {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 0.8rem;
  }
  
  .history-list {
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 8px;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .history-list h4 {
    margin-top: 0;
    color: #fff;
    font-size: 0.9rem;
    border-bottom: 1px solid #555;
    padding-bottom: 5px;
  }
  
  .history-list ul {
    padding-left: 15px;
    margin: 5px 0;
  }
  
  .history-list li {
    font-size: 0.8rem;
    margin-bottom: 3px;
    color: #ccc;
  }
  
  .more-history {
    font-style: italic;
    color: #888;
  }
  
  .measurement-history {
    margin-top: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 5px;
  }

  .imperial-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .imperial-input label {
    margin-bottom: 0;
    margin-right: 5px;
  }
  
  .imperial-input input {
    width: auto;
    flex: 1;
  }

  .heart-rate-tracker {
    width: 100%;
    max-width: 300px;
  }
  
  .previous-entries {
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    padding: 8px;
  }
  
  .previous-entries h4 {
    margin-top: 0;
    margin-bottom: 8px;
    color: #bbdefb;
    font-size: 0.9rem;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 4px;
  }
  
  .previous-entries ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .previous-entries li {
    padding: 4px 0;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.85rem;
    color: #e0e0e0;
  }
  
  .previous-entries li:last-child {
    border-bottom: none;
  }