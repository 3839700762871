/* Frontend/src/Styles/Profile.css - Complete Refactored Version */

/* ------------- Core Layout Styles ------------- */
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    background: url('/public/Never_ending_limitless_vast_deep_black_cosmic_space__bf7cb501-4f0f-41c1-9036-f46d2a370e70_3.png') center center / cover no-repeat, linear-gradient(to bottom, #00172D, #003366);
    position: relative;
    overflow-x: hidden;
    padding-top: var(--navbar-height);
    margin: 0;
    left: 0;
  }
  
  .timeline-container {
    width: 100%;
    max-width: 100%;
    height: 80vh;
    margin: 20px auto;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    position: relative;
    background: transparent;
  }
  
  .cosmic-canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* ------------- Typography & UI Elements ------------- */
  .profile-title {
    color: #FFFFFF;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin: 20px 0;
    z-index: 10;
    position: relative;
    text-shadow: 0 0 15px rgba(76, 17, 224, 0.8), 0 0 30px rgba(124, 77, 255, 0.6);
    animation: titleGlow 3s infinite alternate;
  }
  
  /* Arrange Stars Button */
  .profile-container button,
  .arrange-stars-button {
    padding: 12px 28px;
    background: linear-gradient(135deg, #4c11e0, #7c4dff);
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 15px 0 25px;
    box-shadow: 0 4px 15px rgba(76, 17, 224, 0.3);
    letter-spacing: 0.5px;
    z-index: 10;
    position: relative;
  }
  
  .profile-container button:hover,
  .arrange-stars-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(76, 17, 224, 0.5);
  }
  
  .profile-container button:active {
    transform: translateY(-1px);
    box-shadow: 0 4px 10px rgba(76, 17, 224, 0.3);
  }
  
  /* Active state for arranging mode */
  .profile-container button.arranging {
    background: linear-gradient(135deg, #ff4081, #ff9100);
    animation: pulse 1.5s infinite alternate;
  }
  
  /* ------------- Star & Planet Styling ------------- */
  /* Star title styling */
  .star-title {
    font-weight: 600;
    color: white;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    background: rgba(76, 17, 224, 0.3);
    padding: 3px 8px;
    border-radius: 12px;
    white-space: nowrap;
  }
  
  .star-element {
    position: absolute;
    z-index: 1;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 15px;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 15px rgba(76, 17, 224, 0.6);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .star-element:hover {
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(76, 17, 224, 0.9);
  }
  
  /* Planet tooltips */
  .planet-tooltip {
    background: rgba(20, 10, 40, 0.85);
    border-radius: 12px;
    padding: 12px;
    min-width: 200px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    transform: scale(0.95);
    transition: all 0.3s ease;
    border: 1px solid rgba(120, 82, 238, 0.3);
  }
  
  .planet-tooltip:hover {
    transform: scale(1);
    background: rgba(25, 15, 50, 0.9);
  }
  
  .planet-tooltip h5 {
    color: #9f85ff;
    margin: 0 0 8px 0;
    font-size: 1.1rem;
    border-bottom: 1px solid rgba(120, 82, 238, 0.3);
    padding-bottom: 6px;
  }
  
  .planet-tooltip p {
    color: #ffffff;
    margin: 6px 0;
    font-size: 0.95rem;
  }
  
  .planet-tooltip button {
    background: rgba(120, 82, 238, 0.3);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 6px 12px;
    margin-top: 10px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .planet-tooltip button:hover {
    background: rgba(120, 82, 238, 0.6);
  }
  
  /* ------------- Loading & Helper Elements ------------- */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 5, 20, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    backdrop-filter: blur(5px);
  }
  
  .loading-overlay::after {
    content: "";
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-top-color: #4c11e0;
    animation: spin 1s infinite linear;
  }
  
  .profile-info-tip {
    position: absolute;
    top: 80px;
    right: 20px;
    background: rgba(25, 15, 50, 0.9);
    color: white;
    padding: 12px 15px;
    border-radius: 10px;
    font-size: 0.9rem;
    max-width: 250px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 50;
    border-left: 3px solid #4c11e0;
    animation: fadeInTip 1s ease-out;
  }
  
  .profile-info-tip::before {
    content: "ℹ️";
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  /* ------------- Animations ------------- */
  @keyframes titleGlow {
    from { text-shadow: 0 0 10px rgba(120, 82, 238, 0.8), 0 0 30px rgba(76, 17, 224, 0.5); }
    to { text-shadow: 0 0 15px rgba(120, 82, 238, 0.9), 0 0 40px rgba(76, 17, 224, 0.7); }
  }
  
  @keyframes pulse {
    from { box-shadow: 0 0 10px rgba(255, 64, 129, 0.5); }
    to { box-shadow: 0 0 20px rgba(255, 64, 129, 0.8); }
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  @keyframes fadeInTip {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  /* ------------- Responsive Styles ------------- */
  /* Desktop specific overrides */
  @media (min-width: 769px) {
    .scrollable-page {
      width: 100%;
      padding: 0;
      overflow: hidden;
    }
    
    .profile-container {
      width: 100vw;
    }
    
    .profile-content {
      position: relative;
      z-index: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  /* Tablet & mobile adjustments */
  @media (max-width: 768px) {
    .profile-container {
      padding: 10px;
      height: 100vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    
    .profile-title {
      font-size: 2.2rem;
      margin: 20px 0 15px;
    }
    
    .profile-container button {
      padding: 10px 20px;
      font-size: 1rem;
    }
    
    .planet-tooltip {
      min-width: 150px;
      padding: 8px;
    }
    
    .planet-tooltip h5 {
      font-size: 0.95rem;
    }
    
    .planet-tooltip p {
      font-size: 0.85rem;
    }
    
    .timeline-container {
      width: 95%;
      min-height: 60vh;
    }
    
    .star-element {
      padding: 10px;
    }
  }
  
  /* Very small screens */
  @media (max-width: 480px) {
    .profile-title {
      font-size: 1.8rem;
    }
    
    .profile-container button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  
  /* ------------- Dashboard Compatibility ------------- */
  /* Ensure these styles don't affect Dashboard */
  .innovative-dashboard-container {
    position: static;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: visible;
  }
  
  .dashboard-widgets-container {
    display: grid;
    visibility: visible;
    opacity: 1;
  }