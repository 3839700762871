/* frontend/src/Styles/BillingHistory.css */
.billing-history-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .billing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .billing-header h1 {
    margin: 0;
    color: #333;
  }
  
  .back-button {
    padding: 8px 16px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .back-button:hover {
    background-color: #e5e5e5;
  }
  
  .loading-state {
    text-align: center;
    padding: 40px;
    color: #666;
    font-style: italic;
  }
  
  .no-subscription, .no-invoices {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 20px 0;
  }
  
  .no-subscription p, .no-invoices p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .primary-button {
    padding: 10px 20px;
    background-color: #4c11e0;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .primary-button:hover {
    background-color: #3900c2;
  }
  
  .refresh-button {
    padding: 8px 16px;
    background-color: transparent;
    border: 1px solid #4c11e0;
    color: #4c11e0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .refresh-button:hover {
    background-color: rgba(76, 17, 224, 0.1);
  }
  
  .invoices-list {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .invoice-header {
    display: flex;
    background-color: #f5f5f5;
    font-weight: 600;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .invoice-row {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .invoice-row:last-child {
    border-bottom: none;
  }
  
  .invoice-cell {
    padding: 12px 16px;
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .invoice-cell.description {
    flex: 2;
  }
  
  .invoice-cell.amount {
    font-weight: 500;
  }
  
  .invoice-cell.actions {
    flex: 0.8;
    justify-content: flex-end;
    gap: 8px;
  }
  
  .status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .status-paid {
    background-color: #e6f7ed;
    color: #00864e;
  }
  
  .status-open {
    background-color: #fff4e6;
    color: #e67700;
  }
  
  .status-void {
    background-color: #f3f4f6;
    color: #6b7280;
  }
  
  .view-button, .download-button {
    padding: 4px 8px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .view-button {
    background-color: #e6e6ff;
    color: #4c11e0;
  }
  
  .download-button {
    background-color: #f0f0f0;
    color: #444;
  }
  
  .view-button:hover, .download-button:hover {
    opacity: 0.8;
  }
  
  .billing-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .info-text {
    color: #666;
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .invoice-header {
      display: none;
    }
    
    .invoice-row {
      flex-direction: column;
      padding: 12px;
      border-bottom: 1px solid #e5e5e5;
    }
    
    .invoice-cell {
      padding: 6px 0;
      border-bottom: none;
    }
    
    .invoice-cell:before {
      content: attr(data-label);
      font-weight: 600;
      width: 120px;
      display: inline-block;
    }
    
    .invoice-cell.actions {
      justify-content: flex-start;
    }
    
    .billing-footer {
      flex-direction: column;
      gap: 12px;
    }
  }