/* Enhanced Grocery List Styles */
:root {
    --primary-color: #4c11e0;
    --primary-hover: #6a19ff;
    --primary-light: rgba(76, 17, 224, 0.1);
    --primary-lighter: rgba(76, 17, 224, 0.05);
    --accent-color: #00d2ff;
    --success-color: #2ecc71;
    --delete-color: #e74c3c;
    --warning-color: #f39c12;
    --text-dark: #333;
    --text-medium: #666;
    --text-light: #999;
    --border-color: #e0e0e0;
    --background-light: #ffffff;
    --background-off: #f8f9fa;
    --background-notepad: #fff8e1;
    --background-modal: rgba(0, 0, 0, 0.6);
    --box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    --border-radius: 10px;
    --animation-duration: 0.3s;
}

/* Modal backdrop with proper centering */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-modal);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;
}

/* Main modal container */
.grocery-list-modal {
    position: relative;
    width: 100%;
    max-width: 700px;
    max-height: 90vh;
    background-color: var(--background-light);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
    animation: modalFadeIn var(--animation-duration) ease;
    margin: 0 auto;
}

@keyframes modalFadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Modal header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(135deg, var(--primary-color), var(--primary-hover));
    color: white;
    padding: 15px 20px;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.modal-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
}

.title-icon {
    font-size: 1.25rem;
}

.close-modal-btn {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: background-color var(--animation-duration);
}

.close-modal-btn:hover {
    background: rgba(255, 255, 255, 0.3);
}

/* Modal body */
.modal-body {
    padding: 20px;
    overflow-y: auto;
    max-height: calc(90vh - 60px); /* Account for header */

}

/* Action buttons container */
.action-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 20px;
    background-color: var(--background-off);
    color: var(--text-dark);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: all var(--animation-duration);
    width: 100%;
    max-width: 250px;
}

.action-button:hover {
    background-color: var(--primary-lighter);
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.button-icon {
    font-size: 1.1rem;
}

/* Date selector section */
.date-selector {
    background-color: var(--background-off);
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 20px;
}

.section-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0;
    margin-bottom: 15px;
    color: var(--primary-color);
    font-size: 1.2rem;
}

.section-icon {
    font-size: 1.1rem;
}

.date-fields {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.date-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.date-field label {
    font-weight: 500;
    color: var(--text-dark);
    font-size: 0.9rem;
}

.date-picker {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color var(--animation-duration);
}

.date-picker:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* Generate button */
.generate-btn {
    width: 100%;
    padding: 14px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background-color var(--animation-duration);
    margin-top: 10px;
}

.generate-btn:hover {
    background-color: var(--primary-hover);
}

.generate-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Saved lists section */
.saved-lists {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    overflow-y: auto;
    max-height: 400px;
}

.saved-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    overflow: hidden;
    transition: transform var(--animation-duration), box-shadow var(--animation-duration);
    background-color: var(--primary-color);
    color: white;
}

.saved-list-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.247);
}

.list-button {
    background: none;
    border: none;
    padding: 14px;
    text-align: left;
    cursor: pointer;
    color: var(--text-dark);
    font-size: 1rem;
    flex-grow: 1;
    transition: background-color var(--animation-duration);
}

.list-button:hover {
    background-color: var(--background-off);
}

.list-date {
    font-weight: 500;
}

.list-actions {
    display: flex;
    gap: 10px;
    padding: 15px 20px;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-light);
    position: sticky;
    bottom: 0;
    z-index: 5;
}

.action-icon-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    color: var(--text-medium);
    padding: 14px;
    transition: all var(--animation-duration);
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-icon-btn.delete-btn:hover {
    background-color: rgba(231, 76, 60, 0.1);
    color: var(--delete-color);
}

.action-icon-btn.export-btn:hover {
    background-color: var(--primary-light);
    color: var(--primary-color);
}

/* Loading container */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    color: var(--text-medium);
}

.loading-container .spinner {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
}

/* Empty lists message */
.empty-lists-message {
    text-align: center;
    padding: 40px 20px;
    color: var(--text-medium);
}

/* Back button */
.back-btn {
    width: 100%;
    padding: 12px;
    background-color: var(--background-off);
    color: var(--text-dark);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all var(--animation-duration);
}

.back-btn:hover {
    background-color: var(--primary-lighter);
    border-color: var(--primary-color);
    color: var(--primary-color);
}

/* Export modal */
.export-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    animation: fadeIn var(--animation-duration) ease;
}

.export-modal-content {
    width: 90%;
    max-width: 350px;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 25px;
    box-shadow: var(--box-shadow);
    animation: scaleIn var(--animation-duration) ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes scaleIn {
    from { transform: scale(0.9); }
    to { transform: scale(1); }
}

.export-title {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.export-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-bottom: 20px;
}

.export-option-btn {
    padding: 14px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--background-light);
    color: var(--text-dark);
    font-size: 1rem;
    cursor: pointer;
    transition: all var(--animation-duration);
}

.export-option-btn:hover {
    background-color: var(--primary-lighter);
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.export-option-btn.pdf-btn {
    background-color: var(--primary-lighter);
    border-color: var(--primary-color);
    color: var(--primary-color);
    font-weight: 600;
}

.export-option-btn.pdf-btn:hover {
    background-color: var(--primary-light);
}

.cancel-export-btn {
    width: 100%;
    padding: 12px;
    background-color: var(--background-off);
    color: var(--text-dark);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all var(--animation-duration);
}

.cancel-export-btn:hover {
    background-color: rgba(231, 76, 60, 0.1);
    border-color: var(--delete-color);
    color: var(--delete-color);
}

/* Grocery display */
.grocery-display {
    display: flex;
    flex-direction: column;
    height: 100%; /* Account for header */
    max-height: clac(90vh - 60px);
}

.list-header {
    padding: 15px 20px;
    border-bottom: 1px solid var(--border-color);
    text-align: center;
    position: relative;
}

.list-title {
    margin: 0;
    color: var(--primary-color);
    font-size: 1.3rem;
}

.list-date-display {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 0.9rem;
    color: var(--text-medium);
    background-color: var(--background-off);
    padding: 4px 10px;
    border-radius: 20px;
}

.list-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: var(--background-notepad);
    max-height: 50vh;
}

/* Grocery list container */
.grocery-list-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Search bar */
.search-bar-container {
    position: relative;
    margin-bottom: 10px;
}

.search-input {
    width: 100%;
    padding: 12px 15px;
    padding-right: 40px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 0.95rem;
    transition: border-color var(--animation-duration);
}

.search-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.clear-search-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--text-light);
    cursor: pointer;
    transition: color var(--animation-duration);
}

.clear-search-btn:hover {
    color: var(--delete-color);
}

/* Category styling */
.grocery-category {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform var(--animation-duration), box-shadow var(--animation-duration);
}

.grocery-category:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    background-color: var(--background-off);
    border-bottom: 1px solid var(--border-color);
}

.category-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-size: 1.1rem;
    color: var(--text-dark);
}

.category-icon {
    font-size: 1.2rem;
}

.add-item-btn {
    background-color: transparent;
    color: var(--primary-color);
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color var(--animation-duration);
}

.add-item-btn:hover {
    background-color: var(--primary-lighter);
}

/* Grocery items */
.grocery-items {
    list-style: none;
    padding: 15px;
    margin: 0;
}

.grocery-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 8px;
    border-radius: 6px;
    background-color: var(--background-light);
    border: 1px solid var(--border-color);
    transition: all var(--animation-duration);
}

.grocery-item:last-child {
    margin-bottom: 0;
}

.grocery-item:hover {
    border-color: var(--primary-lighter);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.grocery-item.checked {
    opacity: 0.7;
    text-decoration: line-through;
    background-color: var(--background-off);
}

.item-left {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
}

.check-button {
    min-width: 22px;
    height: 22px;
    background-color: white;
    border: 2px solid var(--border-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all var(--animation-duration);
    padding: 0;
    font-size: 0.8rem;
    font-weight: bold;
}

.check-button:hover {
    border-color: var(--success-color);
}

.check-button.checked {
    background-color: var(--success-color);
    border-color: var(--success-color);
}

.item-details {
    display: flex;
    flex-direction: column;
}

.item-name {
    font-weight: 500;
    color: var(--text-dark);
    font-size: 1rem;
    margin-bottom: 3px;
}

.item-quantity {
    font-size: 0.9rem;
    color: var(--text-medium);
}

.item-right {
    display: flex;
    align-items: center;
}

.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-light);
    font-size: 0.9rem;
    padding: 5px;
    border-radius: 4px;
    transition: all var(--animation-duration);
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button:hover {
    background-color: rgba(231, 76, 60, 0.1);
    color: var(--delete-color);
}

/* Add category button */
.add-category-btn {
    background-color: white;
    border: 1px dashed var(--border-color);
    color: var(--primary-color);
    padding: 12px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all var(--animation-duration);
    text-align: center;
    width: 100%;
}

.add-category-btn:hover {
    background-color: var(--primary-lighter);
    border-color: var(--primary-color);
}

/* Notes section */
.notes-section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    padding: 15px;
}

.notes-title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1rem;
    color: var(--text-dark);
}

.notes-input {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 0.95rem;
    resize: vertical;
    min-height: 80px;
    transition: border-color var(--animation-duration);
    font-family: 'Arial', sans-serif;
}

.notes-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

/* Empty list message */
.empty-list-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    color: var(--text-medium);
}

.empty-icon {
    font-size: 2.5rem;
    color: var(--text-light);
    margin-bottom: 15px;
    opacity: 0.5;
}

.empty-suggestion {
    font-size: 0.9rem;
    margin-top: 10px;
    color: var(--text-light);
}

/* Empty search results */
.empty-search-results {
    text-align: center;
    padding: 30px 20px;
    color: var(--text-medium);
}

/* Actions at the bottom */
.list-actions {
    display: flex;
    gap: 10px;
    padding: 15px 20px;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-light);
}

.action-btn {
    flex: 1;
    padding: 12px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all var(--animation-duration);
}

.action-btn.save-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
}

.action-btn.save-btn:hover {
    background-color: var(--primary-hover);
}

.action-btn.export-btn {
    background-color: var(--primary-lighter);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.action-btn.export-btn:hover {
    background-color: var(--primary-light);
}

.action-btn.back-btn {
    background-color: var(--background-off);
    color: var(--text-dark);
    border: 1px solid var(--border-color);
}

.action-btn.back-btn:hover {
    background-color: var(--border-color);
}

.action-icon {
    font-size: 1.1rem;
}

/* Responsive styles */
@media (min-width: 768px) {
    .date-fields {
        flex-direction: row;
    }
    
    .date-field {
        flex: 1;
    }
}

@media (max-width: 480px) {
    .grocery-list-modal {
        max-height: 95vh;
    }

    .modal-title {
        font-size: 1.3rem;
    }
    
    .date-field label,
    .notes-title {
        font-size: 0.85rem;
    }
    
    .category-title {
        font-size: 1rem;
    }
    
    .item-name {
        font-size: 0.95rem;
    }
    
    .item-quantity {
        font-size: 0.85rem;
    }
    
    .action-btn {
        font-size: 0.9rem;
        padding: 10px;
    }
} 

/* Nutrition Tabs Styling */
.nutrition-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: var(--background-off);
    padding: 5px;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.nutrition-tab {
    flex: 0 0 auto;
    min-width: 70px;
    white-space: nowrap;
}

.nutrition-tab:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: var(--text-dark);
}

.nutrition-tab.active {
    background-color: white;
    color: var(--text-dark);
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.tab-icon {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.protein-icon {
    color: #dc3545; /* Bootstrap danger/red */
}

.carb-icon {
    color: #28a745; /* Bootstrap success/green */
}

.fat-icon {
    color: #ffc107; /* Bootstrap warning/yellow */
}

.other-icon {
    color: #6c757d; /* Bootstrap secondary/gray */
}

.nutrition-indicator {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    font-size: 0.9rem;
}

/* Nutrition Stats styling */
.nutrition-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stat-item {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 120px;
    margin-bottom: 10px;
    padding: 5px;
}

.stat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 1.1rem;
}

.stat-icon.protein {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545;
}

.stat-icon.carb {
    background-color: rgba(40, 167, 69, 0.1);
    color: #28a745;
}

.stat-icon.fat {
    background-color: rgba(255, 193, 7, 0.1);
    color: #ffc107;
}

.stat-icon.other {
    background-color: rgba(108, 117, 125, 0.1);
    color: #6c757d;
}

.stat-details {
    flex: 1;
    min-width: 0;
}

.stat-label {
    display: block;
    font-size: 0.8rem;
    color: var(--text-medium);
    margin-bottom: 2px;
}

.stat-value {
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 3px;
}

.stat-bar {
    height: 4px;
    background-color: var(--background-off);
    border-radius: 2px;
    overflow: hidden;
}

.stat-fill {
    height: 100%;
    border-radius: 2px;
}

.protein-fill {
    background-color: #dc3545;
}

.carb-fill {
    background-color: #28a745;
}

.fat-fill {
    background-color: #ffc107;
}

.other-fill {
    background-color: #6c757d;
}

/* Category tag for filtered views */
.item-category-tag {
    display: inline-block;
    font-size: 0.75rem;
    background-color: var(--background-off);
    color: var(--text-medium);
    padding: 1px 6px;
    border-radius: 10px;
    margin-left: 8px;
}

/* Placeholder for check button in read-only mode */
.check-button-placeholder {
    width: 22px;
    height: 22px;
    margin-right: 12px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .nutrition-tabs {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: auto;
        padding: 5px;
        -webkit-overflow-scrolling: touch;
    }
    
    .nutrition-tab {
        flex: 0 0 80px;
    }
    
    .stat-item {
        flex: 0 0 100%;
    }
}

/* Animation for tab changes */
.nutrition-tab {
    position: relative;
    overflow: hidden;
}

.nutrition-tab::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--primary-color);
    transition: width 0.3s ease;
}

.nutrition-tab.active::after {
    width: 100%;
}

.nutrition-tab.active {
    transform: translateY(-2px);
}

/* Make sure tab icons are properly sized */
.tab-icon {
    font-size: 1.25rem;
}

/* Make sure active tab stands out visually */
.nutrition-tab.active {
    font-weight: 500;
}

/* Ensure tabs have consistent height */
.nutrition-tab span {
    margin-top: 5px;
    white-space: nowrap;
}

