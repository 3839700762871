/* MealPlanCreator.css */
.meal-program-modal {
    max-width: 900px;
    width: 95%;
    max-height: 90vh;
}

/* Nutrition goals section */
.nutrition-targets {
    background-color: #f8f5ff;
    border-radius: 8px;
    padding: 15px;
    margin-top: 15px;
}

.nutrition-targets h4 {
    color: #4c11e0;
    margin-top: 0;
    margin-bottom: 10px;
}

.macro-targets {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 6px;
    padding: 10px 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.macro-target {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.target-label {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}

.target-value {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

/* Macro bar styles */
.macro-bar {
    height: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: 10px 0;
}

.macro-segment {
    height: 100%;
    transition: width 0.3s ease;
}

.macro-segment.protein {
    background-color: #4caf50;
}

.macro-segment.carbs {
    background-color: #2196f3;
}

.macro-segment.fat {
    background-color: #ff9800;
}

/* Macro legend styles */
.macros-legend {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.legend-item {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.legend-color {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 5px;
}

.legend-color.protein {
    background-color: #4caf50;
}

.legend-color.carbs {
    background-color: #2196f3;
}

.legend-color.fat {
    background-color: #ff9800;
}

/* Day nutrition summary */
.day-nutrition {
    margin: 5px 0;
}

.nutrition-summary {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 6px;
    padding: 8px;
    margin-bottom: 8px;
    font-size: 14px;
}

.meal-groups-preview {
    font-size: 12px;
    color: #666;
}

.meal-group-preview {
    margin-bottom: 3px;
}

/* Current selection header */
.current-selection-header {
    background-color: #f8f5ff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.current-selection-header h3 {
    margin: 0;
    color: #4c11e0;
}

.meal-time {
    font-size: 14px;
    color: #666;
}

/* Meal item styles */
.meal-item {
    background-color: white;
    border: 1px solid #e6e6fa;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.2s ease;
}

.meal-item:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.meal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.meal-header h4 {
    margin: 0;
    color: #4c11e0;
    font-size: 16px;
}

.meal-actions {
    display: flex;
    gap: 5px;
}

.meal-details {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 14px;
}

.macro-pill {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 13px;
}

.macro-pill.protein {
    background-color: #e8f5e9;
    color: #388e3c;
}

.macro-pill.carbs {
    background-color: #e3f2fd;
    color: #1976d2;
}

.macro-pill.fat {
    background-color: #fff3e0;
    color: #f57c00;
}

.meal-ingredients,
.meal-recipe,
.meal-notes {
    width: 100%;
    margin-top: 10px;
    font-size: 13px;
    color: #666;
    line-height: 1.4;
}

.meal-recipe.collapsed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

/* Week summary styles */
.week-summary {
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
}

.week-summary h4 {
    margin-top: 0;
    color: #4c11e0;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
    margin-bottom: 15px;
}

.week-nutrition-summary {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.nutrition-overview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-bottom: 15px;
}

.nutrition-item {
    background-color: #f8f8fe;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
}

.nutrition-label {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
}

.nutrition-value {
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.days-overview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.day-summary {
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.day-summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 8px;
}

.day-summary-header h5 {
    margin: 0;
    color: #4c11e0;
}

.day-calories {
    font-weight: 500;
    color: #333;
}

.day-summary-content {
    font-size: 14px;
}

.meal-count {
    color: #666;
    margin-bottom: 8px;
}

.meal-groups-summary {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.meal-group-summary {
    display: flex;
    align-items: flex-start;
}

.meal-group-name {
    font-weight: 500;
    color: #333;
    margin-right: 8px;
    min-width: 90px;
}

.meal-group-meals {
    color: #666;
    flex: 1;
}

/* Single plan styles */
.single-plan-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.plan-overview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.meal-summary-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.meal-summary-item {
    background-color: white;
    border: 1px solid #e6e6fa;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.meal-summary-item h4 {
    margin: 0 0 10px 0;
    color: #4c11e0;
    font-size: 16px;
    border-bottom: 1px dashed #e6e6fa;
    padding-bottom: 5px;
}

.meal-summary-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.meal-nutrition {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.meal-nutrition span {
    background-color: #f8f8fe;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 13px;
}

.meal-summary-ingredients,
.meal-summary-recipe {
    font-size: 14px;
    line-height: 1.5;
    color: #666;
}

.nutrition-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 15px;
}

/* For small screens */
@media (max-width: 768px) {
    .macro-targets {
        flex-direction: column;
        gap: 10px;
    }
    
    .nutrition-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .nutrition-overview {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .meal-group-summary {
        flex-direction: column;
    }
    
    .meal-group-name {
        margin-bottom: 5px;
    }
}