/* Enhanced Calendar UI - Version 2 */
.tracker-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(135deg, rgba(25, 25, 40, 0.95), rgba(35, 35, 60, 0.95));
    border-radius: 16px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  /* Calendar container */
  .rbc-calendar {
    background-color: rgba(30, 30, 50, 0.7);
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    padding: 15px;
    font-family: 'Poppins', sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  /* Calendar header */
  .rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 15px;
    background: linear-gradient(135deg, rgba(106, 27, 154, 0.8), rgba(156, 39, 176, 0.8));
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .rbc-toolbar button {
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
    padding: 10px 16px;
    border-radius: 8px;
    transition: all 0.3s ease;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  
  .rbc-toolbar button:hover {
    background-color: rgba(255, 255, 255, 0.25);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .rbc-toolbar button.rbc-active {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }
  
  /* Month view styling */
  .rbc-month-view {
    border-radius: 10px;
    overflow: hidden;
    border: none;
    background-color: rgba(18, 18, 30, 0.6);
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .rbc-month-header {
    background: linear-gradient(90deg, rgba(70, 30, 105, 0.9), rgba(90, 40, 130, 0.9));
    padding: 5px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .rbc-header {
    padding: 12px 0;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 1.5px;
    border: none;
  }
  
  .rbc-day-bg {
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: rgba(40, 40, 60, 0.4);
  }
  
  .rbc-day-bg:hover {
    background-color: rgba(70, 40, 100, 0.4);
    transform: scale(1.02);
    z-index: 5;
    box-shadow: 0 0 15px rgba(106, 27, 154, 0.3);
  }
  
  .rbc-off-range-bg {
    background-color: rgba(15, 15, 25, 0.7);
  }
  
  .rbc-today {
    background-color: rgba(106, 27, 154, 0.25);
    box-shadow: inset 0 0 20px rgba(106, 27, 154, 0.2);
    border: 2px solid rgba(128, 90, 213, 0.5);
  }
  
  /* Date number styling */
  .rbc-date-cell {
    padding: 8px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
    transition: all 0.2s ease;
  }
  
  .rbc-date-cell:hover {
    color: white;
    transform: scale(1.1);
  }
  
  .rbc-date-cell.rbc-now {
    color: #d500f9;
    font-weight: bold;
  }
  
  /* Event styling */
  .event {
    border-radius: 8px;
    padding: 6px 10px;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: all 0.3s ease;
    height: 100%;
    min-height: 28px;
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transform-origin: center;
  }
  
  .event:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    z-index: 10;
  }
  
  .event-content {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85%;
  }
  
  .event-title {
    display: flex;
    align-items: center;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .event-source {
    font-size: 0.75rem;
    margin-left: 5px;
    opacity: 0.8;
    background-color: rgba(255, 215, 0, 0.3);
    padding: 2px 4px;
    border-radius: 4px;
  }
  
  .event-icons {
    display: flex;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateX(10px);
  }
  
  .event:hover .event-icons {
    opacity: 1;
    transform: translateX(0);
  }
  
  .event-icons svg {
    cursor: pointer;
    transition: transform 0.2s ease, color 0.2s ease;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .event-icons svg:hover {
    transform: scale(1.3);
    color: white;
  }
  
  /* Program marker */
  .program-marker {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent #ffd700 transparent transparent;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  }
  
  /* Event types */
  .workout-event .event-title svg {
    color: #bb86fc;
  }
  
  .mealPlan-event .event-title svg {
    color: #03dac6;
  }
  
  .regular-source {
    background: linear-gradient(135deg, rgba(106, 27, 154, 0.9), rgba(156, 39, 176, 0.9));
  }
  
  .program-source {
    background: linear-gradient(135deg, rgba(120, 100, 20, 0.9), rgba(200, 170, 0, 0.9));
    border: 2px solid rgba(255, 215, 0, 0.7) !important;
  }
  
  .completed {
    background: linear-gradient(135deg, rgba(70, 70, 70, 0.9), rgba(100, 100, 100, 0.9));
  }
  
  /* Action buttons */
  .tracker-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 25px;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  
  .tracker-buttons button {
    background: linear-gradient(135deg, rgba(106, 27, 154, 0.9), rgba(156, 39, 176, 0.9));
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;
    letter-spacing: 0.5px;
    min-width: 180px;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .tracker-buttons button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.4s ease;
  }
  
  .tracker-buttons button:hover::before {
    left: 100%;
  }
  
  .tracker-buttons button:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(106, 27, 154, 0.4);
    background: linear-gradient(135deg, rgba(126, 47, 174, 0.9), rgba(186, 59, 206, 0.9));
  }
  
  .tracker-buttons button:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .tracker-buttons button svg {
    font-size: 1.2rem;
  }
  
  /* Generate workout button */
  .tracker-buttons button:nth-child(1) {
    background: linear-gradient(135deg, #6a1b9a, #9c27b0);
  }
  
  /* Manual workout button */
  .tracker-buttons button:nth-child(2) {
    background: linear-gradient(135deg, #512da8, #7e57c2);
  }
  
  /* Generate meal plan button */
  .tracker-buttons button:nth-child(3) {
    background: linear-gradient(135deg, #0288d1, #29b6f6);
  }
  
  /* Manual meal plan button */
  .tracker-buttons button:nth-child(4) {
    background: linear-gradient(135deg, #0097a7, #26c6da);
  }
  
  /* Loading state */
  .tracker-loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .spinner {
    width: 60px;
    height: 60px;
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    border-top-color: #6a1b9a;
    animation: spin 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(106, 27, 154, 0.5);
  }
  
  .tracker-loading p {
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.9);
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  /* Calendar toolbar */
  .calendar-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px 20px;
    background: linear-gradient(135deg, rgba(106, 27, 154, 0.8), rgba(156, 39, 176, 0.8));
    border-radius: 12px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  }
  
  .calendar-toolbar-buttons button,
  .calendar-toolbar-views button {
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
    padding: 10px 16px;
    margin: 0 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  
  .calendar-toolbar-buttons button:hover,
  .calendar-toolbar-views button:hover {
    background-color: rgba(255, 255, 255, 0.25);
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .calendar-toolbar-views button.active {
    background-color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    border-bottom: 2px solid white;
  }
  
  .calendar-header-label {
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    padding: 8px 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Empty day placeholder */
  .empty-day-placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
    background: rgba(106, 27, 154, 0.1);
    border-radius: 8px;
  }
  
  .empty-day-placeholder:hover {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(106, 27, 154, 0.2);
    transform: scale(1.05);
  }
  
  /* Add event button on empty days */
  .add-event-button {
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: linear-gradient(135deg, #6a1b9a, #9c27b0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transform: translateY(5px);
  }
  
  .custom-day-cell {
    position: relative;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
  }
  
  .rbc-day-bg:hover .add-event-button {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Quick action menu */
  .quick-action-menu {
    position: fixed;
    z-index: 1000;
    background: rgba(20, 20, 35, 0.95);
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    min-width: 200px;
    backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    animation: menuFadeIn 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transform-origin: top left;
  }
  
  .quick-action-menu-item {
    padding: 14px 18px;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    font-weight: 500;
  }
  
  .quick-action-menu-item:last-child {
    border-bottom: none;
  }
  
  .quick-action-menu-item:hover {
    background: linear-gradient(90deg, rgba(106, 27, 154, 0.7), rgba(156, 39, 176, 0.7));
    padding-left: 24px;
  }
  
  .quick-action-menu-item svg {
    font-size: 1.2rem;
  }
  
  /* Add animation for the quick action menu */
  @keyframes menuFadeIn {
    from { 
      opacity: 0; 
      transform: scale(0.9); 
    }
    to { 
      opacity: 1; 
      transform: scale(1); 
    }
  }
  
  /* Add styles for specific quick action types */
  .quick-action-menu-item[data-action="workout"] svg {
    color: #9c27b0;
  }
  
  .quick-action-menu-item[data-action="meal"] svg {
    color: #03dac6;
  }
  
  /* Enhanced positioning for quick action menu in day view */
  .rbc-time-view .quick-action-menu {
    position: absolute;
    /* Allow positioning at the clicked location */
  }
  
  /* Add hover effect to day cells */
  .rbc-time-view .rbc-day-slot:hover {
    background-color: rgba(106, 27, 154, 0.1);
  }

  .rbc-time-view {
    display: flex;
    flex-direction: column;
    background-color: rgba(18, 18, 30, 0.6);
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
  }
  
  /* Hide the time grid portion completely */
  .rbc-time-content {
    display: none;
  }
  
  /* Expand the header to take full space */
  .rbc-time-header {
    height: calc(100vh - 230px);
    background: linear-gradient(90deg, rgba(70, 30, 105, 0.9), rgba(90, 40, 130, 0.9));
    border-bottom: none;
  }
  
  .rbc-time-header-content {
    flex: 1;
    min-height: 100%;
    border-left: none;
    display: flex;
    flex-direction: column;
  }
  
  /* Style the day header */
  .rbc-time-header-cell {
    flex: 0 0 auto;
    padding: 12px 0;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 1.5px;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  /* Create a scrollable event container for each day */
  .rbc-allday-cell {
    height: 100%;
    position: relative;
    overflow-y: auto;
    padding-bottom: 15px;
  }
  
  /* Style the all-day events container */
  .rbc-allday-events {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
  
  /* Style individual events in the day view */
  .rbc-allday-cell .rbc-event {
    background-color: rgba(106, 27, 154, 0.8);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 12px 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    position: relative;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .rbc-allday-cell .rbc-event:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Style for meal plan events */
  .rbc-allday-cell .mealPlan-event {
    background-color: rgba(0, 123, 255, 0.8);
  }
  
  /* Style for program events */
  .rbc-allday-cell .program-source {
    border: 2px solid rgba(255, 215, 0, 0.7) !important;
  }
  
  /* Style for completed events */
  .rbc-allday-cell .completed {
    background-color: rgba(75, 75, 75, 0.8) !important;
    text-decoration: line-through;
  }
  
  /* Event content styling */
  .rbc-allday-cell .rbc-event-content {
    font-size: 0.95rem;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  /* Placeholder for empty days */
  .rbc-allday-cell .empty-day-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9rem;
  }
  
  .rbc-allday-cell .empty-day-message svg {
    font-size: 2rem;
    margin-bottom: 10px;
    opacity: 0.5;
  }
  
  /* Add event button for empty days */
  .add-event-day-button {
    background: linear-gradient(135deg, #6a1b9a, #9c27b0);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    margin-top: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .add-event-day-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
  }
  
  /* Week view specific styling */
  .rbc-time-view.rbc-time-header-formats {
    flex-direction: column;
  }
  
  .rbc-time-header-content .rbc-row {
    display: flex;
    min-height: 40px;
    box-sizing: border-box;
  }
  
  /* Day view specific styling */
  .rbc-time-view.rbc-day-view .rbc-time-header-content {
    justify-content: flex-start;
  }
  
  .rbc-time-view.rbc-day-view .rbc-allday-cell {
    padding: 15px;
  }
  
  /* Add button positioning for week/day view */
  .rbc-allday-cell {
    position: relative;
  }
  
  .rbc-allday-cell .add-event-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(135deg, #6a1b9a, #9c27b0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .rbc-allday-cell:hover .add-event-button {
    opacity: 1;
  }
  
  /* Remove the overlay for event dragging since we don't use it */
  .rbc-addons-dnd-resize-ew-anchor {
    display: none;
  }
  
  /* When in mobile, adjust layout */
  @media (max-width: 768px) {
    .rbc-time-header {
      height: calc(100vh - 200px);
    }
    
    .rbc-allday-cell .rbc-event {
      padding: 8px 12px;
    }
    
    .rbc-allday-cell .rbc-event-content {
      font-size: 0.85rem;
    }
  }
  
  /* Custom add button in day/week view for time slots */
  .custom-time-slot {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .custom-time-slot .add-event-button {
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: linear-gradient(135deg, #6a1b9a, #9c27b0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transform: translateY(5px);
  }
  
  .custom-time-slot:hover .add-event-button {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Custom style for day header in week view */
  .custom-day-header {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .add-event-button-header {
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: linear-gradient(135deg, #6a1b9a, #9c27b0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transform: translateY(5px);
  }
  
  .custom-day-header:hover .add-event-button-header {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Context menu */
  .context-menu {
    position: fixed;
    z-index: 1000;
    background: rgba(20, 20, 35, 0.95);
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    min-width: 200px;
    backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    animation: menuFadeIn 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    padding: 5px;
  }
  
  .context-menu-item {
    padding: 12px 16px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 2px 0;
  }
  
  .context-menu-item:hover {
    background: linear-gradient(90deg, rgba(106, 27, 154, 0.7), rgba(156, 39, 176, 0.7));
  }
  
  .context-menu-divider {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 5px 0;
  }
  
  /* Subscription modal */
  .subscription-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
  }
  
  .subscription-modal-content {
    background: linear-gradient(135deg, #1e1e2f, #2d2d44);
    border-radius: 20px;
    padding: 35px;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.5);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
  }
  
  .subscription-modal-content::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(106, 27, 154, 0.1) 0%, transparent 70%);
    z-index: -1;
  }
  
  .subscription-modal h2 {
    font-size: 2rem;
    margin-bottom: 25px;
    text-align: center;
    background: linear-gradient(90deg, #7b1fa2, #e040fb);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;
  }
  
  .limit-message {
    text-align: center;
    margin-bottom: 35px;
    font-size: 1.15rem;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .subscription-options {
    display: flex;
    gap: 30px;
    margin-bottom: 35px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .subscription-option {
    flex: 1;
    min-width: 280px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    padding: 28px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .subscription-option::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top right, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
    z-index: -1;
  }
  
  .subscription-option:hover {
    transform: translateY(-10px) scale(1.03);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.4);
  }
  
  .subscription-option.premium {
    background: linear-gradient(135deg, rgba(80, 20, 130, 0.4), rgba(124, 58, 237, 0.4));
    border: 2px solid rgba(224, 64, 251, 0.5);
  }
  
  .subscription-option h3 {
    font-size: 1.6rem;
    margin-bottom: 15px;
    text-align: center;
    color: white;
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .subscription-option.premium h3::after {
    content: "✦ RECOMMENDED";
    position: absolute;
    top: -25px;
    right: -10px;
    font-size: 0.7rem;
    background: linear-gradient(90deg, #ff9a8b, #ff6a88);
    padding: 5px 10px;
    border-radius: 20px;
    transform: rotate(5deg);
  }
  
  .subscription-option .price {
    font-size: 2rem;
    margin-bottom: 25px;
    text-align: center;
    font-weight: bold;
    color: white;
    position: relative;
  }
  
  .subscription-option ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 35px;
  }
  
  .subscription-option li {
    padding: 10px 0;
    position: relative;
    padding-left: 30px;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .subscription-option li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #64ffda;
    font-weight: bold;
  }
  
  .subscription-option.premium li:before {
    color: #ff9a8b;
  }
  
  .upgrade-button {
    width: 100%;
    padding: 14px;
    border: none;
    border-radius: 30px;
    background: linear-gradient(90deg, #7b1fa2, #e040fb);
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
  }
  
  .upgrade-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    transition: all 0.4s ease;
  }
  
  .upgrade-button:hover::before {
    left: 100%;
  }
  
  .upgrade-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(224, 64, 251, 0.4);
  }
  
  .upgrade-button.premium {
    background: linear-gradient(90deg, #6200ea, #7c4dff);
  }
  
  .close-button {
    display: block;
    margin: 0 auto;
    padding: 12px 24px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
  }
  
  .close-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    transform: translateY(-2px);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Toast notifications */
  .Toastify__toast {
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
  }
  
  .Toastify__toast--success {
    background: linear-gradient(135deg, rgba(0, 150, 136, 0.9), rgba(0, 188, 212, 0.9));
  }
  
  .Toastify__toast--error {
    background: linear-gradient(135deg, rgba(229, 57, 53, 0.9), rgba(227, 93, 91, 0.9));
  }
  
  .Toastify__progress-bar {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2));
  }
  
  /* Animations and transitions */
  @keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  .shimmer {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0)
    );
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .tracker-container {
      padding: 10px;
      border-radius: 0;
      height: calc(100vh - 50px);
    }
    
    .rbc-toolbar {
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }
    
    .calendar-toolbar {
      flex-direction: column;
      gap: 10px;
    }
    
    .calendar-toolbar-buttons,
    .calendar-toolbar-views {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    
    .subscription-options {
      flex-direction: column;
    }
    
    .tracker-buttons {
      flex-direction: column;
      align-items: center;
    }
    
    .tracker-buttons button {
      width: 100%;
      max-width: 300px;
    }
    
    .rbc-calendar {
      padding: 5px;
    }
  }
  
  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(135deg, rgba(106, 27, 154, 0.8), rgba(156, 39, 176, 0.8));
    border-radius: 5px;
    border: 2px solid rgba(18, 18, 30, 0.6);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(135deg, rgba(126, 47, 174, 0.8), rgba(176, 59, 196, 0.8));
  }
  
  /* Week and day view */
 
.rbc-time-view {
    background-color: rgba(18, 18, 30, 0.6);
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
  }
  
  /* Fix week/day view header */
  .rbc-time-header {
    background: linear-gradient(90deg, rgba(70, 30, 105, 0.9), rgba(90, 40, 130, 0.9));
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .rbc-time-header-content {
    border-left: none;
  }
  
  .rbc-header {
    padding: 12px 0;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 1.5px;
    border: none;
  }
  
  /* Fix time slots */
  .rbc-time-content {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .rbc-timeslot-group {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .rbc-time-slot {
    border-top: none;
  }
  
  .rbc-time-gutter {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.85rem;
    font-weight: 500;
    text-align: center;
  }
  
  .rbc-label {
    padding: 5px 5px;
  }
  
  /* Fix event display in time grid */
  .rbc-event-content {
    font-size: 0.85rem;
    line-height: 1.2;
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Week and day view - event styling */
  .rbc-time-view .rbc-event {
    background-color: rgba(106, 27, 154, 0.8);
    border-radius: 6px;
    border: none;
    padding: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease;
  }
  
  .rbc-time-view .rbc-event:hover {
    transform: scale(1.02);
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
  
  /* Meal plan events in time view */
  .rbc-time-view .mealPlan-event {
    background-color: rgba(0, 123, 255, 0.8);
  }
  
  /* Program events in time view */
  .rbc-time-view .program-source {
    border: 2px solid rgba(255, 215, 0, 0.7) !important;
  }
  
  /* Completed events in time view */
  .rbc-time-view .completed {
    background-color: rgba(75, 75, 75, 0.8) !important;
    text-decoration: line-through;
  }
  
  /* Today highlight in week view */
  .rbc-time-view .rbc-today {
    background-color: rgba(106, 27, 154, 0.15);
  }
  
  /* Day cell selection styles */
  .rbc-day-slot .rbc-background-event {
    opacity: 0.5;
  }
  
  .rbc-day-slot .rbc-selected-cell {
    background-color: rgba(106, 27, 154, 0.3);
  }
  
  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }
  
  /* Add status icon for events */
  .event-status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
  }
  
  .event-status.completed {
    background-color: #4CAF50;
  }
  
  .event-status.pending {
    background-color: #FFC107;
  }
  
  /* Empty day with plus button in week/day view */
  .rbc-day-slot.rbc-time-column {
    position: relative;
  }
  
  .rbc-day-slot .add-event-button {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .rbc-day-slot:hover .add-event-button {
    opacity: 1;
  }
  
  /* Fix for event labels in compact views */
  @media (max-width: 768px) {
    .rbc-event-label {
      display: none;
    }
    
    .rbc-event-content {
      font-size: 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  /* Fix week number layout */
  .rbc-month-view .rbc-row-content {
    z-index: 4;
  }
  
  /* Fix the time indicator line */
  .rbc-current-time-indicator {
    background-color: #ff4081;
    height: 2px;
    z-index: 3;
    opacity: 0.7;
  }
  
  /* Add hover effect to day cells in day view */
  .rbc-day-slot:hover {
    background-color: rgba(106, 27, 154, 0.1);
  }
  
  /* Modal background fix for mobile */
  @media (max-width: 768px) {
    .subscription-modal-content {
      width: 95%;
      padding: 20px;
    }
    
    .subscription-option {
      min-width: 100%;
      margin-bottom: 15px;
    }
    
    .calendar-toolbar {
      padding: 10px;
    }
    
    .calendar-toolbar-buttons button,
    .calendar-toolbar-views button {
      padding: 8px 12px;
      font-size: 0.85rem;
    }
  }