/* CosmicTimelineItem.css */  
.cosmic-tooltip, .planet-tooltip {
  background: rgba(0, 0, 0, 0.85);
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 10px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  width: 320px;  /* Reduced width */
  overflow: visible; /* No scrolling */
}

.cosmic-tooltip h4, .planet-tooltip h5 {
  margin: 0;
}

.planet-tooltip p {
  margin: 5px 0;
}

.achievement {
  font-style: italic;
  color: #ffd700;
}

/* Pulsating Glow */
.pulsating-glow {
  animation: pulse 2s infinite alternate;
}

.edit-tooltip {
  background: rgba(0, 0, 0, 0.85);
  color: #ffffff;
  padding: 12px;
  border-radius: 10px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.manual-input {
  width: 60px;
  margin: 10px 0;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
}

.conversion-display {
  margin: 10px 0;
  font-size: 14px;
  color: #ccc;
}

/* Styles for the orbiting window */
.orbiting-window {
  position: relative;
  max-width: 250px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  overflow: auto;
  font-family: 'Roboto', sans-serif;
}

.orbiting-window h4 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #ffd700;
}

.orbiting-window p,
.orbiting-window li {
  font-size: 14px;
  margin: 5px 0;
}

.orbiting-window .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}

.orbiting-window ul {
  padding-left: 20px;
  list-style-type: disc;
}

.star-title {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.5rem;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
}

.arrange-button {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  background-color: #ffd700;
  border: none;
  border-radius: 5px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
}

.arrange-button:hover {
  background-color: #e6c200;
}

/* Optional: Differentiate stars when arranging */
.arranging-star {
  cursor: grab;
  opacity: 0.8;
}

.regular-star {
  cursor: pointer;
}

/* Improved scrollbar styling */
.edit-tooltip::-webkit-scrollbar,
.planet-tooltip::-webkit-scrollbar {
  width: 6px;
}

.edit-tooltip::-webkit-scrollbar-track,
.planet-tooltip::-webkit-scrollbar-track {
  background: transparent;
}

.edit-tooltip::-webkit-scrollbar-thumb,
.planet-tooltip::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

/* Compact layout utilities */
.compact-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  font-size: 0.8rem;
}

.compact-buttons {
  display: flex;
  gap: 4px;
  justify-content: center;
  margin: 4px 0;
}

.compact-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  font-size: 0.9rem;
}

.compact-input {
  padding: 4px 6px;
  font-size: 0.9rem;
  height: 28px;
}

.compact-label {
  font-size: 0.8rem;
  margin-bottom: 2px;
}

/* Added for better form layout in edit tooltips */
.edit-tooltip .form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.edit-tooltip .checkbox-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin: 5px 0;
}

.edit-tooltip .button-group {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 10px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 235, 120, 0.8);
  }
  to {
    transform: scale(1.1);
    box-shadow: 0 0 25px rgba(255, 235, 0, 1);
  }
}