/* CSS for MealPlanDetailsModal */
.meal-modal-content {
    background: white;
    padding: 25px;
    border-radius: 12px;
    max-width: 650px;
    width: 90%;
    margin: auto;
    max-height: 85vh;
    overflow-y: auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.meal-modal-content h2 {
    color: #4c11e0;
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
}

.meal-plan-metadata {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    align-items: center;
}

.nutrition-toggle {
    background-color: #6a19ff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: all 0.2s ease;
}

.nutrition-toggle:hover {
    background-color: #570eea;
    transform: translateY(-2px);
}

.nutrition-summary {
    background-color: #f0f7ff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    border-left: 4px solid #4c11e0;
}

.nutrition-summary h3 {
    margin-top: 0;
    margin-bottom: 12px;
    color: #4c11e0;
    font-size: 1.2rem;
}

.nutrition-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 15px;
}

.nutrition-item {
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.nutrition-label {
    display: block;
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 5px;
}

.nutrition-value {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

.macro-chart {
    margin-top: 15px;
    border-top: 1px solid #e0e0e0;
    padding-top: 15px;
}

.macro-bar {
    height: 25px;
    color: white;
    font-weight: 600;
    text-align: center;
    line-height: 25px;
    font-size: 0.9rem;
    border-radius: 4px;
    margin-bottom: 2px;
}

.macro-bar.protein {
    background-color: #4caf50;
}

.macro-bar.carbs {
    background-color: #2196f3;
}

.macro-bar.fat {
    background-color: #ff9800;
}

.macro-legend {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.legend-item {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
}

.legend-color {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 5px;
}

.legend-color.protein {
    background-color: #4caf50;
}

.legend-color.carbs {
    background-color: #2196f3;
}

.legend-color.fat {
    background-color: #ff9800;
}

.meals-section {
    margin-bottom: 20px;
}

.meals-section h3 {
    color: #570eea;
    margin-top: 0;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e0e0e0;
}

.loading-indicator {
    text-align: center;
    padding: 20px;
    color: #666;
}

.meals-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.meal-item {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.meal-item:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.meal-details {
    padding: 15px;
}

.meal-name {
    color: #4c11e0;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.25rem;
    border-bottom: 1px dashed #e0e0e0;
    padding-bottom: 8px;
}

.meal-nutrition {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
    gap: 10px;
}

.meal-calories {
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.9rem;
}

.meal-macros {
    display: flex;
    gap: 10px;
}

.macro-item {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.85rem;
    background-color: #f5f5f5;
}

.macro-label {
    font-weight: 600;
    margin-right: 3px;
}

.protein-label {
    color: #4caf50;
}

.carbs-label {
    color: #2196f3;
}

.fat-label {
    color: #ff9800;
}

.ingredients-section,
.recipe-section,
.notes-section {
    margin-bottom: 12px;
}

.ingredients-section h5,
.recipe-section h5,
.notes-section h5 {
    margin-top: 0;
    margin-bottom: 5px;
    color: #570eea;
    font-size: 1rem;
}

.ingredients-section p,
.recipe-section p,
.notes-section p {
    margin: 0;
    line-height: 1.5;
    color: #444;
    font-size: 0.95rem;
}

.recipe-section {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
}

.notes-section {
    font-style: italic;
    color: #666;
}

/* Edit Mode Styles */
.meal-edit-form {
    padding: 15px;
}

.form-row {
    margin-bottom: 15px;
}

.form-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #444;
}

.form-row input,
.form-row textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.form-row textarea {
    min-height: 80px;
    resize: vertical;
}

.macros-edit {
    margin-bottom: 15px;
}

.macros-inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.macro-input {
    display: flex;
    flex-direction: column;
}

.macro-input label {
    font-size: 0.85rem;
    margin-bottom: 5px;
}

.macro-input input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.title-input {
    width: 100%;
    padding: 8px;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    color: #4c11e0;
}

.add-meal-btn {
    background-color: #4c11e0;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.add-meal-btn:hover {
    background-color: #570eea;
    transform: translateY(-2px);
}

.remove-meal-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.remove-meal-btn:hover {
    background-color: #d32f2f;
}

.no-meals {
    text-align: center;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    color: #666;
}

.modal-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 25px;
}

.modal-buttons button {
    padding: 10px 16px;
    border: none;
    border-radius: 6px;
    background-color: #6b7280;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    flex: 1;
    min-width: 120px;
    max-width: 200px;
}

.modal-buttons button:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.modal-buttons button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.modal-buttons .primary-button {
    background-color: #4c11e0;
}

.modal-buttons .delete-button {
    background-color: #ef4444;
}

.modal-buttons .shopping-list-btn {
    background-color: #10b981;
}

.modal-buttons .active-mode {
    background-color: #4c11e0;
    position: relative;
}

.modal-buttons .active-mode::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background-color: #4c11e0;
    border-radius: 50%;
}

/* Meal Tracking Styles */
.meal-tracking {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e0e0e0;
}

.meal-tracking h5 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #4c11e0;
}

.tracking-options {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.tracking-option {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.tracking-option:hover {
    background-color: #e9e9e9;
}

.tracking-option input {
    margin-right: 8px;
}

.tracking-option.selected {
    background-color: #e8f4fd;
    border: 1px solid #4c11e0;
}

.meal-photo-upload {
    margin-top: 15px;
    text-align: center;
}

.photo-upload-button {
    background-color: #f5f5f5;
    border: 1px dashed #ccc;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.photo-upload-button:hover {
    background-color: #e9e9e9;
    border-color: #999;
}

.photo-upload-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #666;
}

.photo-preview {
    margin-top: 15px;
    position: relative;
}

.photo-preview img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.photo-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ai-analysis-results {
    margin-top: 15px;
    background-color: #f0f7ff;
    padding: 15px;
    border-radius: 8px;
    border-left: 4px solid #4c11e0;
}

.ai-analysis-results h6 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #4c11e0;
}

.ai-nutrition-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 0.9rem;
}

.ai-nutrition-label {
    font-weight: 500;
}

/* Consumed indicator */
.meal-consumed-indicator {
    background-color: #4CAF50;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 0.9rem;
}

/* Style for tracking options */
.tracking-options {
    margin: 10px 0;
}

.tracking-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #f5f5f5;
    transition: all 0.2s ease;
}

.tracking-option:hover {
    background-color: #e9e9e9;
}

.tracking-option.selected {
    background-color: #e8f5e9;
    border-left: 4px solid #4CAF50;
}

.tracking-option input[type="checkbox"] {
    margin-right: 10px;
}

/* Better styling for meal items when consumed */
.meal-item {
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.meal-item:has(.meal-consumed-indicator) {
    border-left: 5px solid #4CAF50;
    background-color: #f9fff9;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .meal-modal-content {
        padding: 15px;
        width: 95%;
    }
    
    .nutrition-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .macros-inputs {
        grid-template-columns: 1fr;
    }
    
    .modal-buttons button {
        flex: 1 1 100%;
        max-width: none;
    }
}