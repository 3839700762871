/* Styles/CoachMetricsPanel.css - Refined version */
.coach-metrics-panel {
    background: rgba(13, 17, 30, 0.95);
    border-radius: 12px;
    padding: 1.25rem;
    color: white;
    width: 100%;
    margin-bottom: 1.25rem;
    transition: all 0.3s ease;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 10;
  }
  
  .coach-metrics-panel.expanded {
    max-height: 900px; /* This should be enough for all possible content */
    transition: max-height 0.45s ease;
  }
  
  .coach-metrics-panel.collapsed {
    max-height: 300px; /* This height shows just the first row of metric cards */
    overflow: hidden;
    transition: max-height 0.35s ease;
  }
  
  .coach-metrics-panel.collapsed::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(to bottom, rgba(13, 17, 30, 0) 0%, rgba(13, 17, 30, 0.95) 100%);
    pointer-events: none;
    z-index: 10;
    display: block;
  }
  
  /* Hide the fade when expanded */
  .coach-metrics-panel.expanded::after {
    display: none;
  }

  .metrics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 0.75rem;
  }
  
  .metrics-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #f0f0f0;
    font-weight: 600;
    /* Softer gradient that's less orange/yellow */
    background: linear-gradient(90deg, #8ab4f8 0%, #c2a6f9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  
  .toggle-metrics-btn {
  background: rgba(79, 172, 254, 0.15);
  border: none;
  color: #8ab4f8;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 0.35rem 0.7rem;
  border-radius: 6px;
  transition: all 0.2s;
  border: 1px solid rgba(79, 172, 254, 0.2);
  position: relative;
  z-index: 15;
}
  
  .toggle-metrics-btn:hover {
    background: rgba(79, 172, 254, 0.25);
    transform: translateY(-1px);
  }
  
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1rem;
    margin-bottom: 1.25rem;
  }
  
  .metric-card {
    background: rgba(20, 30, 48, 0.6);
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 1px solid rgba(255, 255, 255, 0.05);
    position: relative;
    height: 100%; /* Ensure all cards have the same height */
  }
  
  .metric-card:hover {
    transform: translateY(-2px);
    background: rgba(30, 40, 60, 0.6);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .metric-icon {
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
  }
  
  /* Use more subtle, professional colors for icons */
  .metric-card.profile .metric-icon {
    color: #a78bfa; /* Purple */
  }
  
  .metric-card.heart-rate .metric-icon {
    color: #f87171; /* Softer red */
  }
  
  .metric-card.hydration .metric-icon {
    color: #60a5fa; /* Softer blue */
  }
  
  .metric-card.steps .metric-icon {
    color: #3b82f6; /* Stronger blue instead of orange */
  }
  
  .metric-card.achievements .metric-icon {
    color: #fbbf24; /* Softer gold */
  }
  
  .metric-card.progress .metric-icon {
    color: #34d399; /* Softer green */
  }
  
  .metric-icon svg {
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.2));
  }
  
  .metric-content h4 {
    margin: 0 0 0.75rem 0;
    font-size: 0.95rem;
    font-weight: 600;
    color: #e0e0e0;
    letter-spacing: 0.02em;
  }
  
  .metric-details {
    font-size: 0.85rem;
  }
  
  .metric-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center;
  }
  
  .metric-label {
    color: #9ca3af; /* Lighter gray for better readability */
  }
  
  .metric-value {
    font-weight: 500;
    color: #f3f4f6; /* Brighter white for better contrast */
  }
  
  .metric-subtext {
    font-size: 0.8rem;
    color: #9ca3af;
    margin-bottom: 0.4rem;
  }
  
  .metric-progress-bar {
    height: 6px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 3px;
    overflow: hidden;
    margin-top: 0.4rem;
  }
  
  /* More subtle gradients for progress bars */
  .progress-fill {
    height: 100%;
    transition: width 0.5s ease;
    border-radius: 3px;
  }
  
  .metric-card.hydration .progress-fill {
    background: linear-gradient(90deg, #60a5fa 0%, #93c5fd 100%); /* Softer blue gradient */
  }
  
  .metric-card.steps .progress-fill {
    background: linear-gradient(90deg, #3b82f6 0%, #60a5fa 100%); /* Blue instead of orange */
  }
  
  .refresh-metrics-btn {
    background: rgba(59, 130, 246, 0.15);
    color: white;
    border: 1px solid rgba(59, 130, 246, 0.3);
    padding: 0.65rem;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.85rem;
    width: 100%;
    transition: all 0.2s;
    font-weight: 500;
    display: block;
    text-align: center;
  }
  
  .refresh-metrics-btn:hover {
    background: rgba(59, 130, 246, 0.25);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Loading state */
  .coach-metrics-panel.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background: rgba(13, 17, 30, 0.95);
  }
  
  .metrics-loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
  }
  
  .spinner {
    width: 30px;
    height: 30px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-top: 2px solid #60a5fa;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .coach-metrics-panel.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    gap: 1rem;
    text-align: center;
    color: #9ca3af;
    background: rgba(13, 17, 30, 0.95);
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .metrics-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .coach-metrics-panel {
      padding: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .metrics-grid {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    .coach-metrics-panel.collapsed {
      max-height: 140px;
    }
  }
  
  @media (max-width: 480px) {
    .coach-metrics-panel.collapsed {
      max-height: 120px;
    }
  }